import dataModel from "data-model";
import userProfile from "user-profile";
import _ from "lodash";
import { isEmpty } from "global-functions";

class GridStateUtils {
  constructor() {
    var self = this;

    // Util to extract filters from grid state (copy/moved from edi.js)
    self.extractGridStateFilters = function (gridState) {
      var scrubbedFilters = {
        dateFilters: new Array(),
        singleValueFilters: new Array(),
        arrayValueFilters: [],
        totalFilters: 0,
      };

      if (gridState == null) return scrubbedFilters;

      var gridFilters =
        gridState && gridState.filters ? gridState.filters : gridState;

      var filterIndexAccessors = [];
      $.each(gridFilters, function (key, value) {
        var lastChar = /.$/.exec(key)[0];
        if (!isNaN(lastChar) && filterIndexAccessors.indexOf(lastChar) == -1)
          filterIndexAccessors.push(lastChar);
      });
      var filters = [];

      var filter = {};
      var operatorKey = "";
      var totalFilters = 0;

      for (var i = 0; i < filterIndexAccessors.length; i++) {
        operatorKey = gridFilters["filterdatafield" + i] + "operator";
        filter = {
          filtercondition: gridFilters["filtercondition" + i],
          filterdatafield: gridFilters["filterdatafield" + i],
          filteroperator: gridFilters["filteroperator" + i],
          filtertype: gridFilters["filtertype" + i],
          filtervalue: gridFilters["filtervalue" + i],
        };
        filter[operatorKey] =
          gridFilters[gridFilters["filterdatafield" + i] + "operator"];

        if (filter.filtervalue !== "Please Choose:") {
          filters.push(filter);
        }
      }

      var singleValueFilters = filters.filter(function (f, i) {
        return (
          f.filtertype !== "datefilter" &&
          filters.reduce((n, val) => {
            return n + (val.filterdatafield == f.filterdatafield);
          }, 0) < 2
        );
      });

      if (singleValueFilters.length > 0) {
        scrubbedFilters["singleValueFilters"] = singleValueFilters;
        totalFilters = totalFilters + singleValueFilters.length;
      }

      scrubbedFilters.arrayValueFilters = filters.filter(function (f, i) {
        return (
          f.filtertype !== "datefilter" &&
          filters.reduce((n, val) => {
            return n + (val.filterdatafield == f.filterdatafield);
          }, 0) > 1
        );
      });

      totalFilters = totalFilters + scrubbedFilters.arrayValueFilters.length;

      var dateFilters = filters.filter(function (f, i) {
        return f.filtertype == "datefilter";
      });

      dateFilters = dateFilters.filter(function (f) {
        return f != null || f != undefined;
      });

      if (dateFilters.length > 0) {
        scrubbedFilters["dateFilters"] = dateFilters;
        totalFilters = totalFilters + dateFilters.length;
      }

      scrubbedFilters.totalFilters = totalFilters;

      return scrubbedFilters;
    };

    // Call this in the grid's ready function to apply any saved state filters for subsequent requests
    // The gridState passed in should be the state.grid.filters from the json stored in UserSearchFilters
    self.loadGridStateFilters = function (grid, gridState, callApplyFilters) {
      if (grid == null || gridState == null) return false;

      var scrubbedFilters = self.extractGridStateFilters(gridState);

      try {
        var filtergroup = undefined;
        var filtervalue = "";
        var filtervalue1 = "";
        var filtercondition = "";
        var filtercondition1 = "";
        var filtertype = "";
        var filterdatafield = "";
        var filteroperator = "";
        var filter1 = undefined;
        var filter;
        var dateFilters = scrubbedFilters.dateFilters;
        var singleValueFilters = scrubbedFilters.singleValueFilters;

        for (var i = 0; i < singleValueFilters.length; i++) {
          filtergroup = new $.jqx.filter();
          filtervalue = singleValueFilters[i].filtervalue;
          filtercondition = singleValueFilters[i].filtercondition;
          filtertype = singleValueFilters[i].filtertype;
          filterdatafield = singleValueFilters[i].filterdatafield;
          filteroperator = singleValueFilters[i][filterdatafield + "operator"];
          filter = filtergroup.createfilter(
            filtertype,
            filtervalue,
            filtercondition
          );
          filtergroup.addfilter(filteroperator, filter);
          grid.jqxGrid("addfilter", filterdatafield, filtergroup);
        }

        //Array value data filters need to be added all to the same filtergroup.  See init filters in orders.js.
        let uniqueArrayValueFilters = [
          ...new Set(
            scrubbedFilters.arrayValueFilters.map(
              (item) => item.filterdatafield
            )
          ),
        ];

        for (let i = 0; i < uniqueArrayValueFilters.length; i++) {
          let arrayFilterGroup = new $.jqx.filter();
          let arrayValueFilters = scrubbedFilters.arrayValueFilters.filter(
            (v) => v.filterdatafield == uniqueArrayValueFilters[i]
          );
          for (let ii = 0; ii < arrayValueFilters.length; ii++) {
            let arrayFilter = arrayFilterGroup.createfilter(
              "stringfilter",
              arrayValueFilters[ii].filtervalue,
              "equal"
            );
            arrayFilterGroup.addfilter(1, arrayFilter);
          }
          grid.jqxGrid(
            "addfilter",
            uniqueArrayValueFilters[i],
            arrayFilterGroup
          );
        }

        for (let i = 0; i < dateFilters.length; i++) {
          filtergroup = new $.jqx.filter();
          var filterObj = dateFilters[i];
          var datafield = filterObj.filterdatafield;

          var filterVal = new Date(filterObj.filtervalue);
          filter = filtergroup.createfilter(
            filterObj.filtertype,
            filterVal,
            filterObj.filtercondition
          );
          filtergroup.addfilter(filterObj.filteroperator, filter);

          if (
            dateFilters[i + 1] != null &&
            dateFilters[i + 1].filterdatafield === datafield
          ) {
            var endDateFilterObj = dateFilters[i + 1];
            var filter2Val = new Date(endDateFilterObj.filtervalue);
            var filter2 = filtergroup.createfilter(
              endDateFilterObj.filtertype,
              filter2Val,
              endDateFilterObj.filtercondition
            );
            filtergroup.addfilter(endDateFilterObj.filteroperator, filter2);
            i = i + 1;
          }

          grid.jqxGrid("addfilter", datafield, filtergroup);
        }

        $(grid).jqxGrid("refreshfilterrow");
        if (callApplyFilters) {
          $(grid).jqxGrid("applyfilters");
        } else {
          $(grid).jqxGrid("refresh");
        }
        if (gridState != null && gridState.sortcolumn) {
          var sortDataField = gridState.sortcolumn;
          var sortOrder =
            gridState.sortdirection != null
              ? gridState.sortdirection.ascending != false
                ? "asc"
                : "desc"
              : "asc";

          grid.jqxGrid("sortby", sortDataField, sortOrder);
        }
      } catch (err) {
        //If filters bomb, just add the default filter
        console.error(err);
      }
    };

    // Call this in the formatData function of the dataAdapter source to send in state for the initial grid data results request
    // *also arranges column orders/sort from the users state.
    self.loadInitGridState = function (data, initGridState, columnsArray) {
      if (initGridState && initGridState["filters"] && initGridState.filters) {
        for (var i = 0; i < initGridState.filters.filterscount; i++) {
          var rangePosition = "";
          if (initGridState.filters["filtertype" + i] == "datefilter") {
            if (
              initGridState.filters["filterdatafield" + i] ==
              initGridState.filters["filterdatafield" + (i + 1)]
            )
              rangePosition = "Begin";
            else if (
              initGridState.filters["filterdatafield" + i] ==
              initGridState.filters["filterdatafield" + (i - 1)]
            )
              rangePosition = "End";

            data[initGridState.filters["filterdatafield" + i] + rangePosition] =
              initGridState.filters["filtervalue" + i];
          }

          if (
            !data[
              initGridState.filters["filterdatafield" + i] + rangePosition
            ] &&
            initGridState.filters["filtervalue" + i] != "Please Choose:"
          ) {
            data[initGridState.filters["filterdatafield" + i] + rangePosition] =
              initGridState.filters["filtervalue" + i];
          } else if (
            typeof data[
              initGridState.filters["filterdatafield" + i] + rangePosition
            ] == "string"
          ) {
            let oldValue =
              data[
                initGridState.filters["filterdatafield" + i] + rangePosition
              ];
            data[initGridState.filters["filterdatafield" + i] + rangePosition] =
              [];
            data[
              initGridState.filters["filterdatafield" + i] + rangePosition
            ].push(oldValue);
            data[
              initGridState.filters["filterdatafield" + i] + rangePosition
            ].push(initGridState.filters["filtervalue" + i]);
          } else if (
            typeof data[
              initGridState.filters["filterdatafield" + i] + rangePosition
            ] == "object"
          ) {
            data[
              initGridState.filters["filterdatafield" + i] + rangePosition
            ].push(initGridState.filters["filtervalue" + i]);
          }
        }
      }

      if (initGridState && initGridState.columns && columnsArray) {
        for (i = 0; i < columnsArray.length; i++) {
          var datafield = columnsArray[i].datafield;
          var stateColumn = initGridState.columns[datafield];
          if (datafield && stateColumn) {
            var order = stateColumn.index;
            columnsArray[i].index = order;
            columnsArray[i].hidden = stateColumn.hidden;
            columnsArray[i].pinned = stateColumn.pinned;
            columnsArray[i].width = stateColumn.width;
          }
        }
        columnsArray.sort(function (a, b) {
          return a.index - b.index;
        });

        if (initGridState.sortcolumn) {
          data.sortDataField = initGridState.sortcolumn;
          data.sortOrder =
            initGridState.sortdirection != null
              ? initGridState.sortdirection.ascending != false
                ? "asc"
                : "desc"
              : "asc";
        }
      }

      Object.keys(data).forEach((x) => {
        if (data[x] === "Please Choose:") {
          delete data[x];
        }
      });

      return data;
    };

    /* -----------------------------
            logGridFilterResults - jqx grid helper - log filters helper method for jqx client side filtering of source data.
            Logs the following message: 'name of the grid - Filtered Search - all filters / values'
            Example log: '#comdataCardGrid - Filtered Search - status=Active, driverStatus=Active, payeeCode=rj, payeeName=johns'
            Args: 
                gridIdName (required) - the html element id="yourGridIdName" name. Can provide the # or not.
                    the getGridFilterResults method.
                params (optional):
                    url - the url to post to, this is set by default to User/LogUserAction
                    pageName - the name of the page, i.e OrderPlanning.aspx or OrderPlanning
            Returns: Nothing atm.
          
         */
    this.logGridFilterResults = function (gridIdName, params) {
      params = params || {};
      if (
        gridIdName !== null &&
        gridIdName !== undefined &&
        gridIdName !== ""
      ) {
        var filterResults = self.getGridFilterResults(gridIdName);
        if (filterResults !== null) {
          // Build the message to log
          var logMsg = gridIdName + " - Filtered Search - ";
          for (let i = 0; i < filterResults.length; i++) {
            var result = filterResults[i];
            logMsg += result.Field + "=" + result.Value + ", ";
          }
          dataModel.addClientSideLog(logMsg, params);
        }
      }
    };
    /* ---------------------------
            getGridFilterResults - jqx grid helper - Get the filters being applied when the grid is being filtered by the user
            params: gridIdName (required) - the html element id="yourGridIdName" name. Can provide the # or not.
            returns: object { Field: string, Value: string };
        */
    this.getGridFilterResults = function (gridIdName) {
      if (gridIdName !== null || gridIdName !== undefined) {
        var gridId =
          gridIdName.indexOf("#") === -1 ? "#" + gridIdName : gridIdName;

        var filters = $(gridId).jqxGrid("getfilterinformation"); // Get the filter information from the grid
        // Get just the column and value the user is filtering
        var filterResults = [];
        for (let i = 0; i < filters.length; i++) {
          var filter = filters[i];
          var value = filter.filter.getfilters()[0].value;
          var datafield = filter.filtercolumn;

          filterResults.push({ Field: datafield, Value: value });
        }

        return filterResults;
      }
    };

    this.formatGridHeaders = function (gridId, limitByAgency, result) {
      var grid = $("#" + gridId);
      let numOfRecords;
      if (result == null) {
        var rows = grid.jqxGrid("getrows");
        numOfRecords = rows.length;
      } else {
        numOfRecords = result.length;
      }

      var tmpRows = new Array();
      if (numOfRecords > 0) {
        var state = grid.jqxGrid("getstate");
        var columns = state.columns;
        var visibleColumns = Object.keys(columns);
        let availableColumns;
        if (result == null) {
          availableColumns = Object.keys(rows[0]);
        } else {
          availableColumns = Object.keys(result[0]);
        }

        var columnCount = availableColumns.length;
        var resultRowCounter = 0;
        for (let k = 0; k < numOfRecords; k++) {
          let item;
          if (result == null) {
            item = rows[k];
          } else {
            item = result[k];
          }

          if (typeof limitByAgency === "boolean") {
            if (limitByAgency == true) {
              if (item.agencyId != dataModel.currentAgencyId) {
                continue;
              }
            }
          }
          tmpRows.push({});

          for (let i = 0; i < columnCount; i++) {
            var fieldname = availableColumns[i];
            if (visibleColumns.indexOf(fieldname) > -1) {
              var column = columns[fieldname];
              if (column.hidden == false) {
                tmpRows[resultRowCounter][column.text] = item[fieldname];
              }
            }
          }
          resultRowCounter += 1;
        }
      }
      return tmpRows;
    };

    this.formatGridFilters = (filterinfo, dataObj, excludeAgency, options) => {
      filterinfo = filterinfo || [];
      filterinfo = _.isArray(filterinfo) ? filterinfo : [filterinfo]; // jqx is sneaky here -- when it's a single filter, an object is passed instead of an array of one object...all other cases this will be an array.
      options = options ?? { dateStartFilterPostFix: "from", dateEndFilterPostFix: "to" };

      dataObj = {
        recordStartIndex: dataObj.recordstartindex,
        recordEndIndex: dataObj.recordendindex,
        pageSize: dataObj.pagesize,
        pageNum: dataObj.pagenum,
        sortDataField:
          dataObj.sortOrder ?? dataObj.sortorder
            ? dataObj.sortdatafield ?? dataObj.sortDataField
            : "",
        sortOrder: dataObj.sortorder ?? dataObj.sortOrder,
      };

      if(!excludeAgency) {
        dataObj.agencyId = userProfile.currentAgencyId();
      }

      const skipValues = ["Please Choose:"];
      const actualFilters = filterinfo.filter(
        (x) =>
          x.filter &&
          x.filter.getfilters().some((y) => skipValues.indexOf(y.value) == -1)
      );

      const actualFiltersDatafieldValuePairs = actualFilters.map((item) => {
        const arrayValueFilters = item.filter
          .getfilters()
          .map((x) => ({ value: x.value, type: x.type }))
          .map((x) => ko.toJS(x));

        if (arrayValueFilters.some((x) => x.type === "datefilter")) {
          return {
            [item.datafield + (options.dateStartFilterPostFix ?? "from")]:
              arrayValueFilters[0] && arrayValueFilters[0].value
                ? arrayValueFilters[0].value["toJSON"]
                  ? arrayValueFilters[0].value.toJSON()
                  : arrayValueFilters[0].value
                : null,
            [item.datafield + (options.dateEndFilterPostFix ?? "to")]:
              arrayValueFilters[1] && arrayValueFilters[1].value
                ? arrayValueFilters[1].value["toJSON"]
                  ? arrayValueFilters[1].value.toJSON()
                  : arrayValueFilters[1].value
                : null,
          };
        }

        return {
          [item.datafield]:
            arrayValueFilters.length > 1
              ? arrayValueFilters.map((x) => x.value)
              : arrayValueFilters[0].value,
        };
      });

      _.merge(dataObj, ...actualFiltersDatafieldValuePairs);

      return dataObj;
    };

    this.formatGridFiltersOLD = function (filterinfo, filters) {
      //debugger;
      filters = {
        agencyId: userProfile.currentAgencyId(),
        recordStartIndex: filters.recordstartindex,
        recordEndIndex: filters.recordendindex,
        pageSize: filters.pagesize,
        pageNum: filters.pagenum,
        sortDataField: filters.sortdatafield,
        sortOrder: filters.sortorder,
      };
      for (let i = 0; i < filterinfo.length; i++) {
        var filterName = filterinfo[i].datafield;
        var gridFilters = filterinfo[i].filter.getfilters();
        for (let j = 0; j < gridFilters.length; j++) {
          if (gridFilters.length > 1) {
            if (filters[filterName] == null) {
              filters[filterName] = [];
            }
            if ($.type(gridFilters[j].value) == "date") {
              filters[filterName].push(gridFilters[j].value.toJSON());
            } else {
              filters[filterName].push(gridFilters[j].value);
            }
          } else {
            if ($.type(gridFilters[j].value) == "date") {
              filters[filterName] = gridFilters[j].value.toJSON();
            } else {
              filters[filterName] = gridFilters[j].value;
            }
          }
        }
      }

      return filters;
    };

    // Call this in the grid ready method when just using jqx default grid state
    // Using gridstate stored in localstorage (should be depreciated.)  *Use applyGridState instead*
    this.loadGridState = function (gridId) {
      var state = JSON.parse(localStorage.getItem("jqxGrid" + gridId));
      if (state) {
        var gridState = $("#" + gridId).jqxGrid("getstate");
        state.selectedrowindex = -1;
        state.selectedrowindexes = [];
        state.pagenum = 0;
        state.height = gridState.height;
        state.pagesize = gridState.pagesize;
        state.pagesizeoptions = gridState.pagesizeoptions;
        // state.filters = gridState.filters;
        $("#" + gridId).jqxGrid("loadstate", state);
      } else {
        $("#" + gridId).jqxGrid("loadstate");
      }
    };

    // Apply the basic filters / user saved filters
    this.applyGridState = (gridId, state) => {
      let grid = $("#" + gridId);

      if (grid.length == 0) return false;

      if (state != null) {
        var gridState = grid.jqxGrid("getstate") || {};
        state.selectedrowindex = -1;
        state.selectedrowindexes = [];
        state.pagenum = 0;
        state.height = gridState.height || state.height;
        state.pagesize = gridState.pagesize || state.pagesize;
        state.pagesizeoptions =
          gridState.pagesizeoptions || state.pagesizeoptions;
        grid.jqxGrid("loadstate", state);
      } else {
        grid.jqxGrid("loadstate");
      }
    };

    this.getUserGridFiltersAsync = (gridName, pageName) => {
      pageName = pageName || window.location.pathname;
      return new Promise((resolve, reject) => {
        dataModel
          .ajaxRequest("UserProfile/GetUserSearchFilters", "get", {
            name: gridName,
            pageName: pageName,
          })
          .done((data) => {
            resolve(data);
          })
          .fail((err) => reject(err));
      });
    };

    // A simple callback to handle filtering, best used for virtualmode = false --since the grid does sorting/filtering/paging clientside
    // without additional api calls.
    this.handleClientSideFiltering = (
      cellValue,
      rowData,
      dataField,
      filterGroup,
      defaultFilterResult
    ) => {
      filterGroup = filterGroup || {};
      const jqxFilters = filterGroup.getfilters() || [];

      let isValid = jqxFilters.some((x) => this.filterValidator(x, cellValue));

      return isValid || defaultFilterResult;
    };
  }

  filterValidator = (jqxFilter, cellValue) => {
    jqxFilter = jqxFilter || {};
    if (jqxFilter.value == "Please Choose:") return true;
    if (typeof cellValue == "boolean" && jqxFilter.type == "stringfilter")
      return String(cellValue).indexOf(jqxFilter.value) != -1; // cell value is boolean, but search filter is string/text input
  };
}

let gridStateUtils = new GridStateUtils();
export default gridStateUtils;

import template from './customer-api-setup-modal.html';
import {noop} from "global-functions";
import tendersHttpClient, {
    IAgencyContact,
    ICustomerSetup,
    IExternalBoardCredential,
    IExternalBoardUser
} from "../../tendersHttpClient";
import userProfile from "user-profile";
import {useDispatch} from "ko-data-store";
import {isLoading} from "../../../../../dataStore/actions/appUI";
import {Observable, ObservableArray} from "knockout";
import toastNotify from "geNotyf";
import {showmessage} from "show-dialog-methods";
interface IExternalBoardUserSelectable extends IExternalBoardUser {
    selected: false
}

class ExternalBoardUserModel {
    boardId: number;
    board: string;
    boardUsername: string;
    id: number;
    username: string;

    constructor(x: IExternalBoardUser) {
        this.boardId = x.boardId;
        this.board = x.board;
        this.boardUsername = x.boardUsername;
        this.id = x.id;
        this.username = x.username;
    }
}

class CustomerSetupModel {
    id: number;
    allowBidding: boolean;
    customerId: number;
    customer: string;

    // Observable item(s) are bound/editable in the UI
    carrierProfitPercent = ko.observable().extend({min: 1, max: 100});
    autoBid = ko.observable();
    contactEmail = ko.observable()
    externalBoardCredentials: IExternalBoardCredential[] = []

    constructor(x: ICustomerSetup) {
        this.id = x.id;
        this.customer = x.customer;
        this.allowBidding = x.allowBidding ?? false;
        this.customerId = x.customerId;
        this.autoBid(x.autoBid);
        this.contactEmail(x.contactEmail);
        this.carrierProfitPercent(x.carrierProfitPercent);
        this.externalBoardCredentials = x.externalBoardCredentials;
    }
}

// class AgencyUserContactOption {
//     constructor(public label = "", public value = "") {}
// }

class CustomerApiSetupModalViewModel {
    dispatch = useDispatch();
    onClose = () => {};
    showModal = ko.observable(false)
    customerSelectDDL = ko.observableArray([]);
    selectedCustomer: Observable<ICustomerSetup> = ko.observable().extend({notify: 'always'});
    agencyContactSelectDDL:ObservableArray<string> = ko.observableArray(["Manual Entry"]);
    selectedAgencyContact: Observable<string | undefined> = ko.observable();

    customerSetups: ICustomerSetup[] = [];
    availableBoardUsers: ko.ObservableArray<ExternalBoardUserModel> = ko.observableArray([])

    selectedDatCredential: Observable<ExternalBoardUserModel> = ko.observable()
    selectedTruckstopCredential: Observable<ExternalBoardUserModel> = ko.observable()

    datCredentialsValid = ko.observable(true);
    truckstopCredentialsValid = ko.observable(true);

    errorMsg = ko.observable();

    disableSaveBtn = ko.pureComputed(() => this.datCredentialsValid() === false || this.truckstopCredentialsValid() === false);

    constructor({onClose} : {onClose: () => void}) {
        // Set local onClose and wrap the callback onClose fn
        this.onClose = () => {
            this.showModal(false);

            if(onClose) {
                onClose();
            }
        }

        this.initData();

        this.selectedCustomer.subscribe(async (x) => {
            this.selectedAgencyContact(null);
            this.selectedDatCredential(null);
            this.selectedTruckstopCredential(null);

            if(x) {
                await this.loadCredentialData(ko.toJS(x));
                this.setSelectedAgencyContact(ko.toJS(x));
            }
        });

        this.selectedTruckstopCredential.subscribe(async (x) => {
            if(x) {
                this.truckstopCredentialsValid(await this.validateCredentials(x.id));
            }
            else {
                this.truckstopCredentialsValid(true);
            }
        })

        this.selectedDatCredential.subscribe(async (x) => {
            if(x) {
                this.datCredentialsValid(await this.validateCredentials(x.id))
            }
            else {
                this.datCredentialsValid(true);
            }
        })
    }

    // Used in .html template as a filter
    filterUsersForBoard = (board: string) => {
        return this.availableBoardUsers().filter(x => x.board?.toUpperCase() === board?.toUpperCase())
    }

    handleSave = async () => {
        try {
            this.errorMsg("");
            if(!this.selectedCustomer()) return;

            const payload = {...ko.toJS(this.selectedCustomer())};
            payload.externalBoardCredentials.length = 0;

            if(this.selectedDatCredential()) {
                const data = this.selectedDatCredential();
                const cred = {externalBoardUserId: data.id, boardId: data.boardId };
                let replaceIndex = payload.externalBoardCredentials.findIndex(obj => obj.boardId === cred.boardId);

                if (replaceIndex !== -1) {
                    payload[replaceIndex] = cred
                }
                else {
                    payload.externalBoardCredentials.push(cred);
                }
            }

            if(this.selectedTruckstopCredential()) {
                const data = this.selectedTruckstopCredential();
                const cred = {externalBoardUserId: data.id, boardId: data.boardId };
                let replaceIndex = payload.externalBoardCredentials.findIndex(obj => obj.boardId === cred.boardId);

                if (replaceIndex !== -1) {
                    payload[replaceIndex] = cred
                }
                else {
                    payload.externalBoardCredentials.push(cred);
                }
            }

            if(this.selectedAgencyContact() !== 'Manual Entry') {
                payload.contactEmail = this.selectedAgencyContact();
            }

            this.dispatch(isLoading(true))
            await tendersHttpClient.saveCustomerSetup(payload);
            this.dispatch(isLoading(false));

            toastNotify.success("Customer Api Saved.")

        }
        catch (err) {
            this.errorMsg(err)
            this.dispatch(isLoading(false))
        }
    }

    private setSelectedAgencyContact = (customerData: ICustomerSetup) => {
        const contacts = this.agencyContactSelectDDL();
        const matchEmail = contacts.find(x => x.toUpperCase() === customerData.contactEmail?.toUpperCase());
        this.selectedAgencyContact(matchEmail);
    }

    private initData = async () => {
        try {
            this.dispatch(isLoading(true));
            const customerData = await tendersHttpClient.getCustomerSetupsAsync(userProfile.currentAgencyExId()) as ICustomerSetup[];

            if(!customerData || !customerData.length) {
                this.dispatch(isLoading(false));
                showmessage("This agency does not have an API customer. Please have a customer set up.");
                return;
            }

            this.customerSelectDDL(customerData.filter(x => x.customer).map(x => new CustomerSetupModel(x)));

            const agencyContacts = await tendersHttpClient.getAgencyContacts(userProfile.currentAgencyExId()) as IAgencyContact[];
            const mappedContacts = agencyContacts.map(x => x.email);
            this.agencyContactSelectDDL([...this.agencyContactSelectDDL(), ...mappedContacts]);

            if(customerData.length === 1) {
                const setup =  customerData[0];
                this.selectedCustomer(setup);

                //this.setSelectedAgencyContact(setup);
            }
            this.showModal(true);
            this.dispatch(isLoading(false));
        }
        catch (err) {
            console.error(err)
            this.dispatch(isLoading(false))
        }
    }

    private loadCredentialData = async (customer: ICustomerSetup) => {
        try {

            if(!customer) return;

            this.dispatch(isLoading(true));
            const boardsData = await tendersHttpClient.getExternalBoardUsers(userProfile.currentAgencyExId()) as IExternalBoardUser[];
            this.availableBoardUsers(boardsData.map(x => new ExternalBoardUserModel(x)));

            customer?.externalBoardCredentials.forEach((cred) => {

                const user = this.availableBoardUsers().find(x => x.id === cred.externalBoardUserId && x.boardId === cred.boardId);
                if (user) {
                    if (cred.board?.toUpperCase() === "DAT") {
                        this.selectedDatCredential(user);
                    }
                    if (cred.board?.toUpperCase() === "TRUCKSTOP") {
                        this.selectedTruckstopCredential(user);
                    }
                }
            })

            this.dispatch(isLoading(false));
        }
        catch (err) {
            console.error(err)
            this.dispatch(isLoading(false))
        }
    }

    private validateCredentials = async(externalLoginId: number) => {
        try {
            this.dispatch(isLoading(true))
            const isValid = await tendersHttpClient.validateBoardCredentialsAsync(externalLoginId) as boolean;
            this.dispatch(isLoading(false))

            return isValid;
        }
        catch (error) {
            this.dispatch(isLoading(false))
            toastNotify.error(error);
        }
    }
}

export default  {template, viewModel: CustomerApiSetupModalViewModel}
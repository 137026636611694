import _ from 'lodash';
import router from 'router';
import dataModel from 'data-model';
import userProfile from "user-profile";
import gridState from "jqx.grid-state-component";
import {showconfirm} from "show-dialog-methods";
import { GreatEdgeCustomWidgets } from 'ge.custom-widgets';
import geTreeDDLFilter from 'ge-widgets/geTreeDDLGridFilter';
import useKoElementAsFilter from 'ge-widgets/useKoElementAsFilter';
import { useDispatch } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';
import { datetimeUTC, mapToCityStateZipObject, cleanString, groupBy } from 'global-functions';
import * as ltpCommon from '../loadTractorPostingCommon';
import storageManager from '../../../../utils/storageManager';

const fetchPostLoadDetails = (tractorId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Loadboard/GetPostedTractor/" + tractorId, "GET")
                    .done(data => resolve(data))
                    .fail(error => reject(error));
    })
}

const fetchTractorAdvanceAlertSettings = (tractorId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("orderplanning/GetTractorAdvancedAlertSettings/" + tractorId, "GET")
                    .done((data) => resolve(data))
                    .fail((error) => reject(error));
    })
}

const fetchTrailerGrpItems = () => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Lookup/TrailerPostingTypes", "GET", null, true)
                    .done((data) => resolve(data))
                    .fail((error) => reject(error));
    })
}

const trailerGrpItems = async() => {
    const items = await fetchTrailerGrpItems().catch(() => []);
    return items.map(x => x.description);
}

const actionListHandlers = {
    createCallInHandler: (selectedRow, handlerFn, onErrorCallBackFn) => {
        return {
            action: "Insert Call in Record",
            handler: () => {
                const {movmentId, tractorId, driver1ExId} = selectedRow();

                if(tractorId > 0) {
                    handlerFn({
                        tractorId: tractorId,
                        driverCarrierId: driver1ExId,
                        movmentId,
                        fromOrderPlanningTractorGrid: true
                    })
                }
                else {
                    onErrorCallBackFn("Please select a row item from the grid.");
                }
            }
        }
    },
    updateTractorLocationHandler: (selectedRow, handlerFn, onErrorCallBackFn) => {
        return {
            action: "Update Tractor Location",
            handler: () => {
                const {movmentId, tractorId, driver1ExId} = selectedRow();

                if(tractorId > 0) {
                    handlerFn({
                        tractorId: tractorId,
                        driverCarrierId: driver1ExId,
                        movmentId,
                        fromOrderPlanningTractorGrid: true
                    })
                }
                else {
                    onErrorCallBackFn("Please select a row item from the grid.");
                }
            }
        }
    },
    viewCallInListHandler: (selectedRow, handlerFn, onErrorCallBackFn) => {
        return {
            action: "View Call in Records",
            handler: () => {
                const {movmentId, tractorId, driver1ExId} = selectedRow();

                if(tractorId > 0) {
                    handlerFn({
                        tractorId: tractorId,
                        driverCarrierId: driver1ExId,
                        movmentId,
                        fromOrderPlanningTractorGrid: true
                    })
                }
                else {
                    onErrorCallBackFn("Please select a row item from the grid.");
                }
            }
        }
    },
    radiusSearchHandler: (handlerFn, onSearchCallBackFn) => {
        return {
            action: "Radius Search",
            handler: () => { 
                handlerFn({onSearchCallBackFn});
            }
        }
    },
    alertSettingsHandler: (selectedRow, handlerFn, onErrorCallBackFn) => {
        return {
            action: "Alert Settings",
            handler: () => {
                const {tractorId} = selectedRow();

                if(tractorId > 0) {
                    handlerFn({tractorId});
                }
                else {
                    onErrorCallBackFn("Please select a row item from the grid.");
                }
            }
        }
    },
    exportHandler: (handlerFn) => {
        return {
            action: "Export",
            handler: () => handlerFn()
        }
    },
    matchMyTruckHandler: (selectedRow, onErrorCallBackFn) => {
        return {
            action: "Match My Truck",
            handler: () => {
                const {tractorId} = selectedRow();

                if(tractorId) {
                    const route = `ExternalBoard/v2?tractorId=${tractorId}`;
                    const tabNames = storageManager.get('loadboardTabNames') || [];

                    const w = window.open(route, tabNames.length > 1 ? tabNames[tabNames.length - 1] : tabNames[0]);
                    w.focus();
                }
                else {
                    onErrorCallBackFn("Please select a row item from the grid.");
                }
            }
        }
    },
    postTruckHandler: (selectedRow, handlerFn, onSuccessCallBackFn, onErrorCallBackFn) => {
        return {
            action: "Post Truck",
            handler: () => {
                const {tractorId, tractorExId} = selectedRow();

                if(tractorId > 0) {
                    handlerFn({tractorId, onSuccesAction: onSuccessCallBackFn });
                }
                else {
                    onErrorCallBackFn("Please select a row item from the grid.");
                }
            }
        }
    }
}

/**
 * 
 * @param {JQuery} $grid 
 * @param {Object} param1 
 * @param {ko.Observable} param1.trailerTypes
 * @param {ko.Observable} param1.regionsAndStates
 */
const filterItemsModel = function($grid, {trailerTypes, regionsAndStates}) {

    const tractorPostingTrailerTypeFilter = useKoElementAsFilter($('#tractorPostingTrailerTypeFilter'));
    this.trailerTypes = trailerTypes;
    this.trailerTypesSelectedItems = ko.observableArray([])
    this.trailerTypesSelectedItems.subscribe((items) => {
        if(items && items.length) {
            const filters = tractorPostingTrailerTypeFilter.addFilterToGrid($grid);
            filters({datafield: 'trailerType', values: items }).applyToGrid();
        }
        else {
            tractorPostingTrailerTypeFilter.removeFilterFromGrid($grid)('trailerType')
        }
    });

    const destinationStatesRegionsFilter = useKoElementAsFilter($('#destinationStateRegionsDDL'));
    this.regionsAndStates = regionsAndStates;
    this.destinationStates = ko.observableArray();
    this.selectedDestinationStates = ko.observableArray();
    this.selectedDestinationStates.subscribe((items) => {
        if(items && items.length) {
            const filters = destinationStatesRegionsFilter.addFilterToGrid($grid);
            filters({datafield: 'destinationState', values: items }).applyToGrid();
        }
        else {
            $grid.jqxGrid('removefilter', 'destinationState');
            destinationStatesRegionsFilter.removeFilterFromGrid($grid)('destinationState')
        }
    })

    const postedStatesRegionsFilter = useKoElementAsFilter($('#postedStateRegionsDDL'));
    this.postedStates = regionsAndStates;
    this.selectedPostedStates = ko.observableArray();
    this.selectedPostedStates.subscribe((items) => {
        if(items && items.length) {
            const filters = postedStatesRegionsFilter.addFilterToGrid($grid);
            filters({datafield: 'postedState', values: items }).applyToGrid();
        }
        else {
            postedStatesRegionsFilter.removeFilterFromGrid($grid)('postedState')
        }
    })

    this.clearCustomFilters = () => {
        this.trailerTypesSelectedItems([]);
        this.selectedDestinationStates([]);
        this.selectedPostedStates([]);
    }

    this.getFiltersToSave = () => {
        return {
            trailerTypes: this.trailerTypesSelectedItems(),
            destinationStates: this.selectedDestinationStates(),
            postedStates: this.selectedPostedStates()
        }
    }

    this.filterWidgets = {
        destinationStatesRegionsFilter,
        tractorPostingTrailerTypeFilter,
        postedStatesRegionsFilter
    }
}

export function TractorPostingsComponent({filterData}) {
    const vmBindings = this;
    const dispatch = useDispatch();


    // Grid state + widgets init
    const geDateWidget = new GreatEdgeCustomWidgets();
    const gridName = "tractor-posting-grid";
    let tractorsGrid = $('#'+gridName);
    let userSavedState = {};
    let searchParams = {};
    const gridStateVM = new gridState.viewModel();
    const toolbarActions = ltpCommon.actionItemsList([...gridStateVM.actions]);
    gridStateVM.actions = ko.observableArray([]);
    let trailerGrps = []; 

    const selectedRow = ltpCommon.getSelectedRowData(tractorsGrid);

    // vm data-bindings
    vmBindings.postTractorModal = ko.observable();
    vmBindings.alertSettingsModal = ko.observable();
    vmBindings.callInListModal = ko.observable();
    vmBindings.newCallInModal = ko.observable();
    vmBindings.radiusSearchModal = ko.observable();
    vmBindings.advancedTractorAlertSettings = ko.observable();
    vmBindings.isGridProcessing = false;

    vmBindings.filterItems = new filterItemsModel(tractorsGrid, filterData)

    let isInit = false;
    vmBindings.refreshSavedSearchDDL = ko.observable("");
    vmBindings.clearSelectedSavedSearchDDL = ko.observable(false);
    vmBindings.handleOnSelected = (state) => {
        userSavedState = state;

        if(isInit === false) {
            init();
        }
        else {
            ltpCommon.applyGridState(gridName, state.grid);
            //tractorsGrid.jqxGrid('refreshfilterrow');
        }

    }

    const gridSource = () => {
        return {
            url: "LoadTractorPostings/TractorPostings",
            datatype: "json",    
            beforeprocessing: (records = []) => {
                records.map(x => {
                    x.ptaDate = ltpCommon.mapGridDate(x.ptaDate);
                    //x.postedExternally = ltpCommon.mapToYesNo(x.postedExternally);
                    //x.isTeam = ltpCommon.mapToYesNo(x.isTeam);

                    const cityStateZipObj = mapToCityStateZipObject(cleanString(["(", ")", ","], x.postedLocation).split(" "));
                    x.postedState = cityStateZipObj.state;
                    x.postedCity = cityStateZipObj.city;
                    return x;
                });
            },
            formatdata: (data) => {
                data = {};
                data.agencyId = userProfile.currentAgencyId();
                
                //data = gridStateUtils.formatGridFilters(tractorsGrid.jqxGrid('getfilterinformation'), data);
                return { ...data, ...searchParams }
            },
            datafields: [
                { name: "tractorId", type: "int" },
                { name: "tractorExId", type: "string" },
                { name: "ptaDate", type: "date" },
                { name: "destinationCity", type: "string" },
                { name: "destinationState", type: "string" },
                { name: "postedCity", type: "string" },
                { name: "postedState", type: "string" },
                { name: "postedExternally", type: "boolean" },
                { name: "trailerType", type: "string" },
                { name: "trailerGrp", type: "string" },
                { name: "dispatcher", type: "string" },
                { name: "driver1", type: "string" },
                { name: "driver2", type: "string" },       
                { name: "driver1ExId", type: "string" },
                { name: "postingNotes", type: "string" },
                { name: "isEditable", type: "boolean" },
                { name: "isTeam", type: "boolean" },
                { name: "movmentId", type: "int" }
            ],
            loadComplete: () => {
                vmBindings.isGridProcessing = false;
            }
        };
    } 

    const gridColumns = () => {
        return [
            { text: "Truck Number", datafield: "tractorExId", width: '200'},
            { text: "Dispatched Trailer Type", datafield: "trailerType", width: '200',
                createfilterwidget: vmBindings.filterItems.filterWidgets.tractorPostingTrailerTypeFilter.createfilterwidget(tractorsGrid)
            },
            { text: "Trailer Group", datafield: "trailerGrp", width: '200', filtertype: 'list', filteritems: trailerGrps },
            { text: "Destination City", datafield: "destinationCity", width: '200' },
            { text: "Destination State", datafield: "destinationState", width: '200',
                createfilterwidget: vmBindings.filterItems.filterWidgets.destinationStatesRegionsFilter.createfilterwidget(tractorsGrid)
            },
            { text: "PTA Date", datafield: "ptaDate", width: '200', cellsformat: 'MM/dd/yyyy HH:mm', createfilterwidget: ltpCommon.geDateRangeFilterWidget(geDateWidget,gridName ) },
            { text: "Dispatcher", datafield: "dispatcher", width: '200'},
            { text: "Posted Externally", datafield: "postedExternally",width: '200',//filtertype: 'list', filteritems: ["Any", "Yes", "No"]
                filtertype: 'bool', // checkbox
                columntype: 'checkbox', 
                editable: false
            },
            { text: "Team", datafield: "isTeam",width: '200', //filtertype: 'list', filteritems: ["Any", "Yes", "No"]
                filtertype: 'bool', // checkbox
                columntype: 'checkbox', 
                editable: false
            },
            { text: "Posted City", datafield: "postedCity", width: '200' },
            { text: "Posted State", datafield: "postedState", width: '200',
                createfilterwidget: vmBindings.filterItems.filterWidgets.postedStatesRegionsFilter.createfilterwidget(tractorsGrid)    
            },
            { text: "Driver 1 Name/ID", datafield: "driver1",width: '200', 
                cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                    if(value) {
                        const nameParts = value.replace("(", "").replace(")", "").split(" ");

                        const $el = $(defaulthtml).text(nameParts[1] + " " + nameParts[2]);
                        const $externalId = $('<span>', {style: "color: #333; opacity: .8; margin-right: 4px;", text:  `(${nameParts[0]})`});
                        $el.prepend($externalId);
    
                        return $el[0].outerHTML;
                    }
  
                    return defaulthtml;
                }
            },
            { text: "Driver 2 Name/ID", datafield: "driver2",width: '200',
                cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                    if(value) {
                        const nameParts = value.replace("(", "").replace(")", "").split(" ");

                        const $el = $(defaulthtml).text(nameParts[1] + " " + nameParts[2]);
                        const $externalId = $('<span>', {style: "color: #333; opacity: .8; margin-right: 4px;", text:  `(${nameParts[0]})`});
                        $el.prepend($externalId);
    
                        return $el[0].outerHTML;
                    }
  
                    return defaulthtml;
                }
            },
            { text: "Posting Notes", datafield: "postingNotes", width: '300', filterable: false,
                cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                    const $el = $(defaulthtml).text(value);
                    $el.attr({'title': value});

                    return $el[0].outerHTML;
                }
            },
        ];
    }

    const onReady = () => {
        return () => {
            tractorsGrid.jqxGrid('clearfilters');
            ltpCommon.applyGridState(gridName, userSavedState.grid);

            if(userSavedState.trailerTypes) vmBindings.filterItems.trailerTypesSelectedItems(userSavedState.trailerTypes);
            if(userSavedState.destinationStates) vmBindings.filterItems.selectedDestinationStates(userSavedState.destinationStates);
            if(userSavedState.postedStates) vmBindings.filterItems.selectedPostedStates(userSavedState.postedStates);
            
            tractorsGrid.jqxGrid('refresh');
            tractorsGrid.jqxGrid('render');

            isInit = true;
        }
    }

    const refreshActionItems = ({isEditable = true, isOrder = false}) => {
        const isDriverOwner = userProfile.isUserDriver || userProfile.isOwnerOperator;
        
        const extraItems = [];
        extraItems.push("Match My Truck");
        extraItems.push("Radius Search");
        extraItems.push("Insert Call in Record");
        extraItems.push("Update Tractor Location");
        extraItems.push("View Call in Records");
        extraItems.push("Post Truck");
        extraItems.push("Alert Settings");
        extraItems.push("Export");
        const actionList = toolbarActions(extraItems);

        const handlers = [];
        handlers.push(actionListHandlers.createCallInHandler(selectedRow, vmBindings.newCallInModal, (errorMsg) => alert(errorMsg)));
        handlers.push(actionListHandlers.updateTractorLocationHandler(selectedRow, vmBindings.newCallInModal, (errorMsg) => alert(errorMsg)));
        handlers.push(actionListHandlers.viewCallInListHandler(selectedRow, vmBindings.callInListModal, (errorMsg) => alert(errorMsg)));

        handlers.push(actionListHandlers.radiusSearchHandler(
            vmBindings.radiusSearchModal,
            ({radius, radiusLocation, searchType}) => { 
                searchParams.searchType = searchType; 
                searchParams.radius = radius; 
                searchParams.radiusLocation = radiusLocation; 
                
                ltpCommon.refreshGrid(tractorsGrid); 
            }
        ));

        handlers.push(actionListHandlers.alertSettingsHandler(selectedRow, handleTractorAdvanceAlertSettings, (errorMsg) => alert(errorMsg)));
        handlers.push(actionListHandlers.exportHandler(handleExportGridResults));
        handlers.push(actionListHandlers.postTruckHandler(selectedRow, handlePostTractor, () => ltpCommon.refreshGrid(tractorsGrid), (errorMsg) => alert(errorMsg)));
        handlers.push(actionListHandlers.matchMyTruckHandler(selectedRow, (errorMsg) => alert(errorMsg)));

        gridStateVM.actions(actionList);
        gridStateVM.extraActionHandlers = handlers;
    }

    const rendertoolbar = (toolbar) => {
        refreshActionItems({});

        const $extraItemsContainer = $("#tractorPostingsToolbarExtraItems");
        $(toolbar).append($extraItemsContainer);
        const jqxGridHelper = geDateWidget.GEWidgetsGridHelper('load-posting-grid');
        
        gridStateVM.clearFilters = () => {
            searchParams = {};
            vmBindings.filterItems.clearCustomFilters();
            jqxGridHelper.clearDateRangeFiltersFromGrid();
            tractorsGrid.jqxGrid('clearfilters');
        }

        gridStateVM.setDefaultSearchOverride = async () => {
            const savedSearches = await loadSavedSearches(tractorsGrid.attr("id"));

            const additionalFilters = vmBindings.filterItems.getFiltersToSave();

            const filters = {
                isDefault: true,
                ...additionalFilters
            }

            gridStateVM.loadSaveSearchModal(
                tractorsGrid,
                (val) => {
                    if(val && val.searchname) {
                        vmBindings.refreshSavedSearchDDL(val.searchname)
                    }
                },
                savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                filters,
                true
            );
        }

        //gridStateVM.setDefaultSearch = () => vmBindings.filterItems.getFiltersToSave();

        // gridStateVM.overrideCustomGridModalOptionsSave = () => {
        //     const filters = gridStateVM.setDefaultSearch();
        //     gridStateVM.saveGridFilters(tractorsGrid, filters);
        // }

        gridStateVM.actions.push("Save Search");

        gridStateVM.loadSaveSearchModalOverride = async () => {
            const savedSearches = await loadSavedSearches(tractorsGrid.attr("id"));

            const additionalFilters = vmBindings.filterItems.getFiltersToSave();

            const filters = {
                isDefault: false,
                ...additionalFilters
            }

            gridStateVM.loadSaveSearchModal(
                tractorsGrid,
                (val) => {
                    if(val && val.searchname) {
                        vmBindings.refreshSavedSearchDDL(val.searchname)
                    }
                },
                savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                filters,
                true
            );

        }

        var $tdLoadPostingGridAction = $("#tdTractorPostingGridAction");
        $tdLoadPostingGridAction.append(gridState.template);
        ko.applyBindingsToDescendants(gridStateVM, $tdLoadPostingGridAction[0]);

        $extraItemsContainer.show();
    }

    const handlePostTractor = async({tractorId}) => {
        const details = await fetchPostLoadDetails(tractorId).catch(() => {});

        vmBindings.postTractorModal(details);
    }

    const handleTractorAdvanceAlertSettings = async({tractorId}) => {
        const details = await fetchTractorAdvanceAlertSettings(tractorId).catch(() => {});
        vmBindings.advancedTractorAlertSettings(details);
    }

    const handleExportGridResults = () => {
        showconfirm("This will export only what is currently shown in the grid results. Do you want to continue?").then((isConfirmed) => {
            if(isConfirmed) ltpCommon.getCSVFromJson(ltpCommon.gridHeaders(gridName), "Tractor Postings");
        });
    }

    const init = async() => {
        trailerGrps = await trailerGrpItems();
        //userSavedState = await ltpCommon.setUserFilters(gridName);
        ltpCommon.renderGrid({gridId: gridName,
            options: {
                source: gridSource(),
                toolbarrenderer: rendertoolbar,
                columns: gridColumns(),
                onReadyCallBackFn: onReady,
                pageable: false
            }
        })
    }
}

import template from './tractor-postings-component.html';
import {loadSavedSearches} from "../../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
export default { viewModel: TractorPostingsComponent, template: template }
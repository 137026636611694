import $ from "jquery";
import * as ko from "knockout";
import dataModel from "data-model";

export interface ICustomerSetup {
    id: number,
    agencyGroup: number,
    allowBidding?: boolean,
    autoBid?: boolean,
    contactEmail?: string,
    customer?: string,
    customerId?: number,
    carrierProfitPercent?: number;
    externalBoardCredentials?: IExternalBoardCredential[]
}

export interface IExternalBoardCredential {
    externalBoardUserId?: number
    board?: string,
    boardId?: number,
    username?: string
}

export interface IExternalBoardUser {
    id: number;
    board?: string,
    boardId?: number,
    username?: string,
    boardUsername?: string
}

export interface IAgencyContact {
    email: string;
    name: string;
}

class TendersHttpClient {
    constructor() {}

    validateBoardCredentialsAsync = (externalLoginId: number) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest(`CustomerAPI/ValidateCredentials/${externalLoginId}`, "GET", null, true)
                .done(resolve)
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }

    sendTenderBidAsync = (tenderNumber: string, bidAmount: number, agency: string) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("CustomerAPI/TenderBid", "POST", {tenderId: tenderNumber, bidAmount, agency}, true)
                .done(resolve)
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }

    getTrailerTypesAsync = (agency: string) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("CustomerAPI/TrailerTypes", "GET", {take: 10, agency}, true)
                .done(resolve)
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }

    getCustomerSetupsAsync = (agency: string) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("CustomerAPI/CustomerSetups", "GET", {agency}, true)
                .done((resp) => resolve(resp as ICustomerSetup[]))
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }

    getExternalBoardUsers = (agency: string, board?: string) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("CustomerAPI/ExternalBoardUsers", "GET", {agency, board}, true)
                .done((resp) => resolve(resp as ICustomerSetup[]))
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }

    getAgencyContacts = (agency: string) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("CustomerAPI/AgencyContacts", "GET", {agency}, true)
                .done((resp) => resolve(resp as IAgencyContact[]))
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }

    saveCustomerSetup = (payload: ICustomerSetup) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest(`CustomerAPI/CustomerSetup/${payload.id}`, "PUT", payload, true)
                .done((resp) => resolve(true))
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }

    sendTenderResponse = (tenderId: string, response: "ACCEPT" | "DECLINE", agency: string) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest(`CustomerAPI/TenderResponse`, "POST", {tenderId, response, agency}, true)
                .done((resp) => resolve(true))
                .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
        })
    }
}

const singleton = new TendersHttpClient();
export default  singleton;
import RateService, {TPIMarketRates} from "../../../../../services/RateService";

interface TenderBidViewModelProps {
    onClose: () => void;
    $data: {
        tenderNumber: string;
        loadId?: number,
        lastBidAmount?: number,
        currentBid?: number
    }

}

class TenderBidViewModel {
    dispatch = useDispatch();
    onClose = () => {}
    tpiMarketRates = ko.observable(TPIMarketRates());

    currentAcceptedBid: number = null;
    lastBidAmount: Observable<number | null> = ko.observable(null);
    _bidInputVal: Observable<number | null> = ko.observable(null);
    bidInput = ko.pureComputed({
        read: this._bidInputVal,
        write: (val) => {
            const parsed = val != null ? Math.round(Number(val) * 100) / 100 : null;
            this._bidInputVal(parsed);
        }
    })

    private tenderNumber: string | null = null

    constructor(props: TenderBidViewModelProps) {
        const {tenderNumber, loadId, lastBidAmount, currentBid} = ko.toJS(props.$data);
        this.tenderNumber = tenderNumber;
        this.lastBidAmount(lastBidAmount);
        this.currentAcceptedBid = currentBid;

        this.onClose = props.onClose ?? noop;
        this.loadTPIRates(loadId);
    }

    handleSubmitBid = async () => {
        try {
            this.dispatch(isLoading(true))
            await tendersHttpClient.sendTenderBidAsync(this.tenderNumber, this._bidInputVal(), userProfile.currentAgencyExId())
            this.dispatch(isLoading(false))

            this.lastBidAmount(this._bidInputVal());
            this._bidInputVal(null);

            toastNotify.success("Bid submitted successfully.");
        }
        catch (err) {
            this.dispatch(isLoading(false))
            toastNotify.error({message: err as string, duration: 5000});
        }
    }

    private loadTPIRates = async (loadId) => {
        const result = await RateService.getSavedRatesForLoad(loadId);
        this.tpiMarketRates(result);
    };
}

import template from './tender-bid-modal.html';
import ko, {Observable} from "knockout";
import toastNotify from "geNotyf";
import {useDispatch} from "ko-data-store";
import {isLoading} from "../../../../../dataStore/actions/appUI";
import tendersHttpClient from "../../tendersHttpClient";
import userProfile from "user-profile";
import {noop} from "global-functions";
export default { viewModel: TenderBidViewModel, template }
import ko from "knockout";
import dataModel from "data-model";
import template from "./carrier-entry-page.html";
import dayjs from "dayjs";
import * as _ from "lodash";
import userProfile from "user-profile";
import { scrollToRequiredField, datetimeUTC } from "global-functions";
import { showmessage, showconfirm } from "show-dialog-methods";
import toast from "geNotyf";
import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../dataStore/actions/appUI";

const fetchFraudCheckAsync = async (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("CarrierContact/CheckContactPassesFraud", "GET", payload)
      .done(resolve)
      .fail((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request.`
        )
      );
  });
};

const ContactFraudResponseModel = ({
  passes = true,
  message = undefined,
} = {}) => {
  const initMsg = `Please verify the contact before saving.`;

  const checked = ko.observable(false);
  const pass = ko.observable(passes);
  const msg = ko.observable(message == null && !checked() ? initMsg : message);

  return {
    passes: pass,
    message: msg,
    checked,
  };
};

var CarrierEntryViewModel = function (params) {
  var self = this;
  self.modalDelete = ko.observable();
  self.errors = ko.observableArray();
  self.isLoading = ko.observable(false);

  self.carrier = ko.observable();

  self.canAddContacts = ko.observable(false);

  const getSecurity = async () => {
    const roles = await userProfile.roles();

    self.canAddContacts(
        roles.some((x) => ["Administrators", "GW Carrier Entry", "Agent"].indexOf(x) > -1)
    );
  };

  self.navbarBannerItems = [
    {
      name: "Accounting Information",
      elementId: "accounting-information-section",
    },
    {
      name: "Operating/Insurance Information",
      elementId: "operating-insurance-section",
    },
    { name: "Contacts", elementId: "contacts-section" },
    { name: "Equipment", elementId: "equipment-section" },
    { name: "Notes", elementId: "carrierNotes-section" },
    { name: "Profile Records", elementId: "profile-section" },
    {
      name: "Call in Records",
      elementId: "callin-records-section",
      isHidden: ko.computed(
        () => self.carrier() && self.carrier().id() > 0 == false
      ),
    },
    {
      name: "Lane History",
      elementId: "lane-history-section",
      isHidden: ko.computed(
        () => self.carrier() && self.carrier().id() > 0 == false
      ),
    },
    {
      name: "Third Party Integration",
      elementId: "third-party-section",
      isHidden: !userProfile.isGwCarrierEntrant,
    },
  ];

  self.loadCarrier = function () {
    var carrierId = window.location.pathname.split("/")[2];
    if (carrierId == undefined) {
      var carrier = self.carrier();
      if (carrier != null) {
        carrierId = carrier.id();
      }
    }
    if (carrierId != undefined) {
      self.isLoading(true);
      dataModel
        .ajaxRequest("carrier/" + carrierId, "GET")
        .done(function (data, status, xhr) {
          if (data.code != undefined)
            document.title = "GE Carrier Entry - " + data.code;
          self.carrier(new CarrierModel(data, self));
          self.isLoading(false);
        })
        .fail(function (error, msg, d) {
          self.errors.push("Unable to load carrier");
          self.isLoading(false);
        });
    } else {
      self.carrier(new CarrierModel({}, self));
    }
  };
  self.loadCarrier();

  getSecurity();
};

var ContactModel = function (item, carrierModel, mainModel) {
  var self = this;
  const dispatchAction = useDispatch();
  self.contactVerifyResponse = ko.observable(ContactFraudResponseModel());

  self.isNewContact = ko.observable(false);
  if (_.isEmpty(item)) {
    self.isNewContact(true);
  }
  self.contactModel = true;
  self.id = ko.observable(item.id);
  self.name = ko.observable(item.name).extend({
    required: {
      onlyIf: function () {
        if (_.isEmpty(item)) {
          return true;
        }
        return false;
      },
    },
  });
  self.title = ko.observable(item.title);
  self.phone = ko.observable(item.phone).extend({
    minLength: 10,
    required: {
      onlyIf: function () {
        if (_.isEmpty(item)) {
          return true;
        }
        return false;
      },
    },
  });
  self.fax = ko.observable(item.fax).extend({ minLength: 10, required: false });
  const _email = ko.observable(item.email);
  self.email = ko
    .pureComputed({
      read: _email,
      write: (val) => {
        if (val != _email()) {
          self.contactVerifyResponse(ContactFraudResponseModel());
        }

        _email(val);
      },
    })
    .extend({ email: true });
  self.terminalId = ko.observable(item.terminal);
  self.terminal = ko.observable(item.terminal);
  self.cityStateZip = ko.observable(item.cityStateZip).extend({
    required: {
      onlyIf: function () {
        if (self.terminal()) {
          return true;
        }
        return false;
      },
    },
  });

  self.terminalSelected = function (item) {
    if (item != null) {
      self.cityStateZip(item.cityStateZip);
    }
  };

  self.acceptedItem = function (contact) {
    carrierModel.contactErrors.removeAll();
    var validationErrors = ko.validation.group(carrierModel);
    carrierModel.isContactLoading(true);
    //If this is an existing carrier, no validation check is required, except for the current section (Contacts).
    //Carrier does not have to be valid.
    if (carrierModel.id()) {
      var contactValidation = ko.validation.group(contact);
      if (contactValidation().length == 0) {
        var newName = contact.name();
        if (!newName.replace(/\s/g, "")) {
          // remove any whitespace
          carrierModel.contacts.pop(contact);
          carrierModel.contactErrors.push("Contact name must be specified.");
          carrierModel.isContactLoading(false);
          return false;
        }
        var newContact = ko.toJS(contact);
        dataModel
          .ajaxRequest(
            "Carrier/AddCarrierContact/" + carrierModel.id(),
            "post",
            newContact
          )
          .done(function (contactId) {
            if (contact.isNewContact()) {
              var index = carrierModel.contacts().length - 1;
              carrierModel.contacts()[index].id(contactId);
              carrierModel.contacts()[index].isNewContact(false);
            }
            carrierModel.isContactLoading(false);
          })
          .fail(function (error, msg, d) {
            carrierModel.contacts.pop(contact);
            carrierModel.contactErrors.push("Unable to save contact");
            carrierModel.isContactLoading(false);
          });
      }
      //If there are validation errors in the contact section, reject the entry
      else {
        carrierModel.contacts.pop(contact);
        contactValidation.showAllMessages();
        carrierModel.contactErrors.push("Please check input and try again");
        return false;
      }
    }
    //If the carrier does not already have an id / is not existing and there are no validation errors in the carrier section,
    //proceed with creating a carrier, which will also insert the carrier contact
    else if (validationErrors().length == 0) {
    }
    //If the carrier does not already have an id / is not existing and there ARE validation errors in the carrier section,
    //reject the entry
    else {
      carrierModel.contacts.pop(contact);
      carrierModel.contactErrors.push(
        "Invalid entry.  Carrier's required fields must be completed for contact to be created"
      );
      carrierModel.isContactLoading(false);
      return false;
    }
  };

  self.deletedItem = function (contact) {
    contact.isDeleting = ko.observable(false);
    contact.deleteWarning = ko.observable(
      "You are about to delete contact: " +
        contact.name() +
        ".  Do you wish to continue?"
    );
    contact.deleteItem = function (contact) {
      contact.isDeleting(true);
      carrierModel.contactErrors.removeAll();
      if (contact != null) {
        dataModel
          .ajaxRequest(
            "Carrier/DeleteCarrierContact/" +
              carrierModel.id() +
              "/" +
              contact.id(),
            "Delete"
          )
          .done(function () {
            contact.isDeleting(false);
            contact.isDeleted = true;
            $("#deleteItem").jqxWindow("close");
          })
          .fail(function (error, msg, d) {
            $("#deleteItem").jqxWindow("close");
            contact.isDeleting(false);
            carrierModel.contactErrors.push("Unable to delete contact");
          });
      } else {
        $("#deleteItem").jqxWindow("close");
        contact.isDeleting(false);
        carrierModel.contactErrors.push("Unable to delete contact");
      }
    };
    contact.cancelDelete = function () {
      $("#deleteItem").jqxWindow("close");
    };
    contact.returnContactToArray = function () {
      if (!contact.isDeleted) {
        carrierModel.contacts.push(contact);
      }
    };
    $("#deleteItem")
      .off("close")
      .on("close", function (event) {
        contact.returnContactToArray();
      });
    mainModel.modalDelete(contact);
    $("#deleteItem").jqxWindow({
      autoOpen: false,
      isModal: true,
      width: 330,
      height: "auto",
      maxWidth: 1000,
      theme: "summer",
      resizable: false,
      closeButtonSize: 20,
    });
    $("#deleteItem").jqxWindow("open");
  };

  self.isContactEmailChanged = ko.pureComputed(() => {
    const emailVal = self.email();
    let changed = false;

    if (item.email != emailVal) {
      changed = true;
      self.contactVerifyResponse(ContactFraudResponseModel());
    }

    return changed;
  });

  self.displayContactFraudMessage = ko.pureComputed(() => {
    if (self.isNewContact() || self.isContactEmailChanged()) {
      if (!self.email()) return false;

      return (
        self.contactVerifyResponse().message() &&
        self.contactVerifyResponse().message().length > 0
      );
    }

    return false;
  });

  self.hideVerifyEmailBtn = ko.pureComputed(() => {
    if (self.email() && (self.isNewContact() || self.isContactEmailChanged())) {
      return self.contactVerifyResponse().checked();
    }

    return true;
  });

  self.hasErrors = ko.pureComputed(() => {
    var errors = ko.validation.group(self);
    return errors().length > 0;
  });

  self.preventSave = ko.pureComputed(() => {
    if (self.hasErrors()) {
      return true;
    }

    if (self.isNewContact() || self.isContactEmailChanged()) {
      // Has been checked yet?
      if (self.contactVerifyResponse().checked() === false) {
        return true;
      }

      return self.contactVerifyResponse().passes() === false;
    }

    return false;
  });

  self.contactFailedFraudCheck = ko.pureComputed(() => {
    if (self.isNewContact() || self.isContactEmailChanged()) {
      if (self.contactVerifyResponse().checked() === false) {
        return true;
      }

      return self.contactVerifyResponse().passes() === false;
    }

    return false;
  });

  self.handleVerifyContact = async () => {
    try {
      mainModel.errors.removeAll();

      if (carrierModel.id() > 0 === false) {

      }

      dispatchAction(isLoading(true));
      const response = await fetchFraudCheckAsync({
        carrierId: carrierModel.id(),
        emailAddress: self.email(),
      });

      dispatchAction(isLoading(false));
      const model = ContactFraudResponseModel({ ...response });
      model.checked(true);
      self.contactVerifyResponse(model);

      if (response.message) {
        showmessage(response.message);
      }
    } catch (err) {
      dispatchAction(isLoading(false));

      if (err) {
        console.error(err);
        showmessage(err);
      }
    }
  };
};

var EquipmentModel = function (item, carrierModel, mainModel) {
  var self = this;
  self.id = ko.observable(item.id);
  self.isNewEquipment = ko.observable(false);
  if (_.isEmpty(item)) {
    self.isNewEquipment(true);
  }
  self.type = ko.observable(item.type).extend({ required: true });
  self.description = ko.observable(item.description);
  self.quantity = ko.observable(item.quantity || 1).extend({ required: true });
  self.primary = ko.observable(item.primary || false);
  self.terminalId = ko.observable(item.terminal);
  self.terminal = ko.observable(item.terminal);
  self.cityStateZip = ko.observable(item.cityStateZip).extend({
    required: {
      onlyIf: function () {
        if (self.terminal()) {
          return true;
        }
        return false;
      },
    },
  });
  self.equipmentTypeId = ko.observable(item.equipmentTypeId);
  self.equipmentType = ko.observable();
  self.equipmentType.subscribe(function (newValue) {
    if (newValue != null) {
      self.description(newValue.description);
      self.equipmentTypeId(newValue.id);
    } else {
      self.description(undefined);
      self.equipmentTypeId(undefined);
    }
  });
  self.disablePrimary = ko.pureComputed(function () {
    var checkedEquipment = ko.utils.arrayFirst(
      ko.unwrap(carrierModel.equipment),
      function (equip) {
        return equip.primary() === true;
      }
    );
    if (checkedEquipment != null && self.primary() == false) {
      return true;
    }
    return false;
  });
  self.terminalSelected = function (item) {
    if (item != null) {
      self.cityStateZip(item.cityStateZip);
    }
  };
  self.acceptedItem = function (equipment) {
    carrierModel.equipmentErrors.removeAll();
    var validationErrors = ko.validation.group(carrierModel);
    carrierModel.isEquipmentLoading(true);
    //If this is an existing carrier, no validation check is required, except for the current section (Equipment).
    //Carrier does not have to be valid.
    if (carrierModel.id()) {
      var equipmentValidation = ko.validation.group(equipment);
      if (equipmentValidation().length == 0) {
        var newEquipment = ko.toJS(equipment);
        dataModel
          .ajaxRequest(
            "Carrier/AddorUpdateEquipment/" + carrierModel.id(),
            "post",
            newEquipment
          )
          .done(function (equipmentId) {
            if (equipment.isNewEquipment()) {
              var index = carrierModel.equipment().length - 1;
              carrierModel.equipment()[index].id(equipmentId);
              carrierModel.equipment()[index].isNewEquipment(false);
            }
            carrierModel.isEquipmentLoading(false);
          })
          .fail(function (error, msg, d) {
            carrierModel.equipment.pop(equipment);
            carrierModel.equipmentErrors.push("Unable to save equipment");
            carrierModel.isEquipmentLoading(false);
          });
      }
      //If there are validation errors in the equipment section, reject the entry
      else {
        carrierModel.equipment.pop(equipment);
        equipmentValidation.showAllMessages();
        carrierModel.equipmentErrors.push("Please check input and try again");
        return false;
      }
    }
    //If the carrier does not already have an id / is not existing and there are no validation errors in the carrier section,
    //proceed with creating a carrier, which will also insert the carrier equipment
    else if (validationErrors().length == 0) {

    }
    //If the carrier does not already have an id / is not existing and there ARE validation errors in the carrier section,
    //reject the entry
    else {
      carrierModel.equipment.pop(equipment);
      carrierModel.equipmentErrors.push(
        "Invalid equipment entry.  Carrier's required fields must be completed for equipment to be created"
      );
      carrierModel.isEquipmentLoading(false);
      return false;
    }
  };

  self.deletedItem = function (equipment) {
    equipment.isDeleting = ko.observable(false);
    equipment.deleteWarning = ko.observable(
      "You are about to delete equipment: " +
        equipment.description() +
        ".  Do you wish to continue?"
    );
    equipment.deleteItem = function (equipment) {
      equipment.isDeleting(true);
      carrierModel.equipmentErrors.removeAll();
      if (equipment != null) {
        dataModel
          .ajaxRequest(
            "Carrier/DeleteCarrierEquipment/" +
              carrierModel.id() +
              "/" +
              equipment.id(),
            "Delete"
          )
          .done(function () {
            equipment.isDeleting(false);
            equipment.isDeleted = true;
            $("#deleteItem").jqxWindow("close");
          })
          .fail(function (error, msg, d) {
            $("#deleteItem").jqxWindow("close");
            equipment.isDeleting(false);
            carrierModel.equipmentErrors.push("Unable to delete equipment");
          });
      } else {
        $("#deleteItem").jqxWindow("close");
        equipment.isDeleting(false);
        carrierModel.equipmentErrors.push("Unable to delete equipment");
      }
    };
    equipment.cancelDelete = function () {
      $("#deleteItem").jqxWindow("close");
    };
    equipment.returnToArray = function () {
      if (!equipment.isDeleted) {
        carrierModel.equipment.push(equipment);
      }
    };
    $("#deleteItem")
      .off("close")
      .on("close", function (event) {
        equipment.returnToArray();
      });
    mainModel.modalDelete(equipment);
    $("#deleteItem").jqxWindow({
      autoOpen: false,
      isModal: true,
      width: 330,
      height: "auto",
      maxWidth: 1000,
      theme: "summer",
      resizable: false,
      closeButtonSize: 20,
    });
    $("#deleteItem").jqxWindow("open");
  };
};

var CarrierNoteModel = function (item, carrierModel, mainModel) {
  var self = this;
  self.id = ko.observable(item.id);
  self.isNewnote = ko.observable(false);
  if (_.isEmpty(item)) {
    self.isNewnote(true);
  }
  self.comment = ko.observable(item.comment).extend({
    required: {
      onlyIf: function () {
        if (self.id()) {
          return false;
        } else {
          return true;
        }
      },
    },
  });
  self.masterNote = ko.observable(item.masterNote);
  self.enteredBy = ko.observable(item.enteredBy);
  self.agencyId = ko.observable(item.agencyId);
  self.createdDate = ko.observable(
    item.createdDate ? datetimeUTC(item.createdDate).format("MM/DD/YYYY") : null
  );
  self.acceptedItem = function (note) {
    toast.open({ type: "info", message: `Note Added` });
    carrierModel.noteErrors.removeAll();
    var validationErrors = ko.validation.group(carrierModel);
    //  carrierModel.isnoteLoading(true);
    //If this is an existing carrier, no validation check is required, except for the current section (note).
    //Carrier does not have to be valid.
    if (carrierModel.id()) {
      var noteValidation = ko.validation.group(note);
      if (noteValidation().length == 0) {
        var newnote = ko.toJS(note);
        dataModel
          .ajaxRequest(
            "Carrier/AddorUpdateCarrierNote/" + carrierModel.id(),
            "post",
            newnote
          )
          .done(function ({ id }) {
            if (note.isNewnote()) {
              var index = carrierModel.carrierNotes().length - 1;
              carrierModel.carrierNotes()[index].id(id);
              carrierModel.carrierNotes()[index].isNewnote(false);
            }
            carrierModel.isNoteLoading(false);
          })
          .fail(function (error, msg, d) {
            carrierModel.carrierNotes.pop(note);
            carrierModel.noteErrors.push("Unable to save note");
            carrierModel.isNoteLoading(false);
          });
      }
      //If there are validation errors in the note section, reject the entry
      else {
        carrierModel.carrierNotes.pop(note);
        noteValidation.showAllMessages();
        carrierModel.noteErrors.push("Please check input and try again");
        return false;
      }
    }
    //If the carrier does not already have an id / is not existing and there are no validation errors in the carrier section,
    //proceed with creating a carrier, which will also insert the carrier note
    else if (validationErrors().length == 0) {
    }
    //If the carrier does not already have an id / is not existing and there ARE validation errors in the carrier section,
    //reject the entry
    else {
      carrierModel.carrierNotes.pop(note);
      carrierModel.noteErrors.push(
        "Invalid note entry.  Required fields must be completed for note to be created"
      );
      carrierModel.isnoteLoading(false);
      return false;
    }
  };

  self.deletedItem = function (note) {
    note.isDeleting = ko.observable(false);
    note.deleteWarning = ko.observable(
      "You are about to delete note: " +
        note.comment() +
        ".  Do you wish to continue?"
    );
    note.deleteItem = function (note) {
      toast.open({ type: "info", message: "Note deleted" });
      note.isDeleting(true);
      carrierModel.noteErrors.removeAll();
      if (note != null) {
        dataModel
          .ajaxRequest(
            "Carrier/DeleteCarrierNote/" + carrierModel.id() + "/" + note.id(),
            "Delete"
          )
          .done(function () {
            note.isDeleting(false);
            note.isDeleted = true;
            $("#deleteItem").jqxWindow("close");
          })
          .fail(function (error, msg, d) {
            $("#deleteItem").jqxWindow("close");
            note.isDeleting(false);
            carrierModel.noteErrors.push("Unable to delete note");
          });
      } else {
        $("#deleteItem").jqxWindow("close");
        note.isDeleting(false);
        carrierModel.noteErrors.push("Unable to delete note");
      }
    };
    note.cancelDelete = function () {
      $("#deleteItem").jqxWindow("close");
    };
    note.returnToArray = function () {
      if (!note.isDeleted) {
        carrierModel.carrierNotes.push(note);
      }
    };
    $("#deleteItem")
      .off("close")
      .on("close", function (event) {
        note.returnToArray();
      });
    mainModel.modalDelete(note);
    $("#deleteItem").jqxWindow({
      autoOpen: false,
      isModal: true,
      width: 330,
      height: "auto",
      maxWidth: 1000,
      theme: "summer",
      resizable: false,
      closeButtonSize: 20,
    });
    $("#deleteItem").jqxWindow("open");
  };
};

var ProfileRecordModel = function (item, carrierModel, mainModel) {
  var self = this;
  self.isNewProfileRecord = ko.observable(false);
  if (_.isEmpty(item)) {
    self.isNewProfileRecord(true);
  }
  self.id = ko.observable(item.id);
  self.occurance = ko.observable(item.occurance).extend({ required: true });
  self.enteredBy = ko.observable(item.enteredBy);
  self.createdDate = ko.observable(item.createdDate);
  self.acceptedItem = function (profileRecord) {
    carrierModel.profileRecordErrors.removeAll();
    var validationErrors = ko.validation.group(carrierModel);
    carrierModel.isProfileRecordLoading(true);

    //If this is an existing carrier, no validation check is required, except for the current section (Profile Records).
    //Carrier does not have to be valid.
    if (carrierModel.id()) {
      var profileRecordValidation = ko.validation.group(profileRecord);
      if (profileRecordValidation().length == 0) {
        var newProfileRecord = ko.toJS(profileRecord);
        dataModel
          .ajaxRequest(
            "Carrier/AddorUpdateCarrierProfileRecord/" + carrierModel.id(),
            "post",
            newProfileRecord
          )
          .done(function (profileObj) {
            if (profileRecord.isNewProfileRecord()) {
              var index = carrierModel.profileRecords().length - 1;
              carrierModel.profileRecords()[index].id(profileObj.id);
              carrierModel.profileRecords()[index].isNewProfileRecord(false);
            }
            let PRIndex = carrierModel
              .profileRecords()
              .map(function (e) {
                return e.id();
              })
              .indexOf(profileObj.id);
            carrierModel
              .profileRecords()
              [PRIndex].enteredBy(profileObj.enteredBy);
            carrierModel
              .profileRecords()
              [PRIndex].createdDate(profileObj.createdDate);
            carrierModel.isProfileRecordLoading(false);
          })
          .fail(function (error, msg, d) {
            carrierModel.profileRecords.pop(profileRecord);
            carrierModel.profileRecordErrors.push(
              "Unable to save profile record"
            );
            carrierModel.isProfileRecordLoading(false);
          });
      }
      //If there are validation errors in the profile records section, reject the entry
      else {
        carrierModel.profileRecords.pop(profileRecord);
        profileRecordValidation.showAllMessages();
        carrierModel.profileRecordErrors.push(
          "Please check input and try again"
        );
        return false;
      }
    }
    //If the carrier does not already have an id / is not existing and there are no validation errors in the carrier section,
    //proceed with creating a carrier, which will also insert the carrier profile record
    else if (validationErrors().length == 0) {
    }
    //If the carrier does not already have an id / is not existing and there ARE validation errors in the carrier section,
    //reject the entry
    else {
      carrierModel.profileRecords.pop(profileRecord);
      carrierModel.profileRecordErrors.push(
        "Invalid profile record entry.  Carrier's required fields must be completed for a profile record to be created"
      );
      carrierModel.isProfileRecordLoading(false);
      return false;
    }
  };

  self.deletedItem = function (profileRecord) {
    profileRecord.isDeleting = ko.observable(false);
    profileRecord.deleteWarning = ko.observable(
      "You are about to delete profile record: " +
        profileRecord.occurance() +
        ".  Do you wish to continue?"
    );
    profileRecord.deleteItem = function (profileRecord) {
      profileRecord.isDeleting(true);
      carrierModel.profileRecordErrors.removeAll();
      if (profileRecord != null) {
        dataModel
          .ajaxRequest(
            "Carrier/DeleteCarrierProfileRecord/" +
              carrierModel.id() +
              "/" +
              profileRecord.id(),
            "Delete"
          )
          .done(function () {
            profileRecord.isDeleting(false);
            profileRecord.isDeleted = true;
            $("#deleteItem").jqxWindow("close");
          })
          .fail(function (error, msg, d) {
            $("#deleteItem").jqxWindow("close");
            profileRecord.isDeleting(false);
            carrierModel.profileRecordErrors.push(
              "Unable to delete profile record"
            );
          });
      } else {
        $("#deleteItem").jqxWindow("close");
        profileRecord.isDeleting(false);
        carrierModel.profileRecordErrors.push(
          "Unable to delete profile record"
        );
      }
    };
    profileRecord.cancelDelete = function () {
      $("#deleteItem").jqxWindow("close");
    };
    profileRecord.returnToArray = function () {
      if (!profileRecord.isDeleted) {
        carrierModel.profileRecords.push(profileRecord);
      }
    };
    $("#deleteItem")
      .off("close")
      .on("close", function (event) {
        profileRecord.returnToArray();
      });
    mainModel.modalDelete(profileRecord);
    $("#deleteItem").jqxWindow({
      autoOpen: false,
      isModal: true,
      width: 330,
      height: "auto",
      maxWidth: 1000,
      theme: "summer",
      resizable: false,
      closeButtonSize: 20,
    });
    $("#deleteItem").jqxWindow("open");
  };
};

var ThirdPartyIntegrationModel = function (item, carrierModel, mainModel) {
  var self = this;
  var originalId = item.thirdPartyId;
  var originalExId = item.thirdPartyCarrierExternalId;
  self.isChanged = ko.observable(false);
  self.thirdParties = ko.observableArray();
  self.thirdPartyId = ko.observable().extend({
    required: {
      onlyIf: function (val) {
        if (_.isEmpty(item)) {
          if (
            self.thirdPartyCarrierExternalId != undefined &&
            self.thirdPartyCarrierExternalId() != undefined
          ) {
            if (self.thirdPartyCarrierExternalId().length > 0) {
              return true;
            }
          }
        }
        return false;
      },
    },
    min: {
      onlyIf: function () {
        if (_.isEmpty(item)) {
          if (
            self.thirdPartyCarrierExternalId != undefined &&
            self.thirdPartyCarrierExternalId() != undefined
          ) {
            if (self.thirdPartyCarrierExternalId().length > 0) {
              return true;
            }
          }
        }
        return false;
      },
      message: "Select valid external partner or remove external id",
      params: 1,
    },
  });
  dataModel
    .ajaxRequest("carrier/GetCarrierThirdParties")
    .done(function (result) {
      for (let i = 0; i < result.length; i++) {
        self.thirdParties.push(result[i]);
      }
      self.thirdParties.unshift({ name: "None Selected", id: 0 });
      //The selected third party Id in drop down needs to be populated after the callback
      self.thirdPartyId(item.thirdPartyId);
    })
    .fail(function (jqXHR, textStatus, errorThrown) {});

  self.currentThirdPartyId = ko.observable(item.id);
  self.thirdPartyCarrierExternalId = ko
    .observable(item.thirdPartyCarrierExternalId)
    .extend({
      required: {
        onlyIf: function () {
          if (_.isEmpty(item)) {
            if (self.thirdPartyId() > 0) {
              return true;
            }
          }
          return false;
        },
      },
    });
  self.thirdPartyCarrierExternalId.subscribe(function (newVal) {
    self.thirdPartyId.valueHasMutated();
    if (newVal != originalExId) {
      self.isChanged(true);
    }
  });

  self.undo = function () {
    self.thirdPartyId(originalId);
    self.thirdPartyCarrierExternalId(originalExId);
    self.isChanged(false);
    carrierModel.thirdPartyIntegrationErrors.removeAll();
  };
  var thirdPartyValidation = ko.validation.group([
    self.thirdPartyCarrierExternalId,
    self.thirdPartyId,
  ]);
  self.acceptedItem = function (thirdParty) {
    var validationErrors = ko.validation.group(carrierModel);
    carrierModel.thirdPartyIntegrationErrors.removeAll();
    if (!carrierModel.id()) {
      if (validationErrors().length > 0) {
        carrierModel.thirdPartyIntegration.pop(thirdParty);
        carrierModel.thirdPartyIntegrationErrors.push(
          "Invalid entry.  Carrier's required fields must be completed for third party data to be saved"
        );
        scrollToRequiredField();
        return false;
      }
      //If this is a new carrier then save the carrier in its entirety if carrier and third party sections are valid
      if (thirdPartyValidation().length == 0) {
        if (!thirdParty.thirdPartyId() > 0) {
          carrierModel.thirdPartyIntegrationErrors.push(
            "Invalid entry.  Third Party External Partner must be selected"
          );
          return false;
        }
        if (
          thirdParty.thirdPartyId() > 0 &&
          thirdParty.thirdPartyCarrierExternalId().length < 1
        ) {
          carrierModel.thirdPartyIntegrationErrors.push(
            "Invalid entry.  Third Party Id must be completed"
          );
          return false;
        }
      }
    } else {
      if (thirdPartyValidation().length > 0) {
        carrierModel.thirdPartyIntegrationErrors.push(
          "Invalid entry.  Required fields must be completed for Third Party data to be saved"
        );
        carrierModel.isThirdPartyIntegrationLoading(false);
        return false;
      }
      if (
        !thirdParty.thirdPartyId() > 0 &&
        thirdParty.thirdPartyCarrierExternalId().length > 0
      ) {
        carrierModel.thirdPartyIntegrationErrors.push(
          "Invalid entry.  Partner and External Id must both be present or removed"
        );
        return false;
      }
      if (
        thirdParty.thirdPartyId() > 0 &&
        thirdParty.thirdPartyCarrierExternalId().length < 1
      ) {
        carrierModel.thirdPartyIntegrationErrors.push(
          "Invalid entry.  Partner and External Id must both be present or removed"
        );
        return false;
      }
      carrierModel.isThirdPartyIntegrationLoading(true);
      var data = {
        CurrentThirdPartyId: thirdParty.currentThirdPartyId(),
        ThirdPartyCarrierExternalId: thirdParty.thirdPartyCarrierExternalId(),
        ThirdPartyId: thirdParty.thirdPartyId,
      };
      dataModel
        .ajaxRequest(
          "Carrier/AddorUpdateCarrierThirdPartyIntegration/" +
            carrierModel.id(),
          "Post",
          data
        )
        .done(function (data, textStatus, jqXHR) {
          originalId = self.thirdPartyId();
          originalExId = self.thirdPartyCarrierExternalId();
          self.isChanged(false);
          thirdParty.currentThirdPartyId(data);
          carrierModel.isThirdPartyIntegrationLoading(false);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.responseJSON.message) {
            carrierModel.thirdPartyIntegrationErrors.push(
              jqXHR.responseJSON.message
            );
          }
          carrierModel.isThirdPartyIntegrationLoading(false);
        });
    }
  };

  self.deletedItem = function (thirdParty) {
    thirdParty.isDeleting = ko.observable(false);
    thirdParty.deleteWarning = ko.pureComputed(function () {
      if (carrierModel.code() != null && carrierModel.code().length > 0) {
        return (
          "You are about to delete third party data for " +
          carrierModel.code() +
          ".  Do you wish to continue?"
        );
      } else {
        return "You are about to delete third party data. Do you wish to continue?";
      }
    });
    thirdParty.deleteItem = function (thirdParty) {
      thirdParty.isDeleting(true);
      carrierModel.thirdPartyIntegrationErrors.removeAll();
      if (thirdParty != null) {
        //If there is a carrier here, then you can proceed with the removal of the third party integration data.
        //If no carrier, then just clear the selections and return
        if (carrierModel.existingCarrier) {
          //If this is an existing carrier, see if there are any third party validation errors first
          if (thirdPartyValidation().length == 0) {
            var data = {
              CurrentThirdPartyId: thirdParty.currentThirdPartyId(),
              ThirdPartyCarrierExternalId:
                thirdParty.thirdPartyCarrierExternalId(),
              ThirdPartyId: thirdParty.thirdPartyId,
            };
            dataModel
              .ajaxRequest(
                "Carrier/DeleteThirdPartyExternalPartner/" + carrierModel.id(),
                "DELETE",
                data
              )
              .done(function (data, textStatus, jqXHR) {
                thirdParty.currentThirdPartyId(undefined);
                thirdParty.thirdPartyCarrierExternalId(undefined);
                thirdParty.thirdPartyId(0);
                thirdParty.isDeleting(false);
                thirdParty.isDeleted = true;
                $("#deleteItem").jqxWindow("close");
              })
              .fail(function (jqXHR, textStatus, errorThrown) {
                $("#deleteItem").jqxWindow("close");
                thirdParty.isDeleting(false);
                carrierModel.thirdPartyIntegrationErrors.push(
                  "Unable to delete third party record"
                );
              });
          } else {
            thirdParty.thirdPartyId(0);
            thirdParty.thirdPartyCarrierExternalId(undefined);
            return false;
          }
        } else {
          thirdParty.thirdPartyId(0);
          thirdParty.thirdPartyCarrierExternalId(undefined);
          return false;
        }
      }
    };
    thirdParty.cancelDelete = function () {
      $("#deleteItem").jqxWindow("close");
    };
    thirdParty.returnToArray = function () {
      if (!thirdParty.isDeleted) {
        carrierModel.thirdPartyIntegration.push(thirdParty);
      }
    };
    $("#deleteItem")
      .off("close")
      .on("close", function (event) {
        thirdParty.returnToArray();
      });
    mainModel.modalDelete(thirdParty);
    $("#deleteItem").jqxWindow({
      autoOpen: false,
      isModal: true,
      width: 330,
      height: "auto",
      maxWidth: 1000,
      theme: "summer",
      resizable: false,
      closeButtonSize: 20,
    });
    $("#deleteItem").jqxWindow("open");
  };
};

var CarrierModel = function (carrier, mainModel) {
  var self = this;

  self.deleteItem = ko.observable();
  self.isReadOnly = true;
  self.contactErrors = ko.observableArray();
  self.isContactLoading = ko.observable(false);
  self.equipmentErrors = ko.observableArray();
  self.isEquipmentLoading = ko.observable(false);
  self.noteErrors = ko.observableArray();
  self.isNoteLoading = ko.observable(false);
  self.profileRecordErrors = ko.observableArray();
  self.isProfileRecordLoading = ko.observable(false);
  self.thirdPartyIntegrationErrors = ko.observableArray();
  self.isThirdPartyIntegrationLoading = ko.observable(false);
  //self.gwCarrierEntry = ko.observable(self.isGwCarrierEntrant());
  self.id = ko.observable(carrier.id);
  self.isEdit = ko.computed(function () {
    return self.id() > 0;
  });
  self.existingCarrier =
    Object.keys(carrier).length === 0 && carrier.constructor === Object
      ? false
      : true;
  self.originalValue = ko.observable(carrier.isActive);
  self.items = ko.observableArray([
    { name: "Inactive", value: false },
    { name: "Active", value: true },
  ]);
  self.disabled = ko.pureComputed(function () {
    var validationErrors = ko.validation.group(mainModel.carrier);
    return validationErrors().length == 0 && self.isReadOnly == false
      ? false
      : true;
  });
  self.displayMessage = ko.pureComputed(function () {
    return !self.isActive() && self.existingCarrier && !self.isReadOnly;
  });
  self.inactiveSaved = ko.observable(false);

  self.OOS = ko.observable(carrier.oos || false);
  self.carrierOOSMsg = ko.computed(function () {
    if (self.OOS())
      return "This carrier has had their DOT Authority Revoked and may not haul freight.";
  });

  self.agencyLinkCode = ko.observable(carrier.agencyLinkCode);
  self.displayRestrictedCarrierMessage = ko.pureComputed(
    () =>
      self.agencyLinkCode() &&
      self.agencyLinkCode() != userProfile.agencyLinkCode()
  );

  self.isActive = ko.observable(false);
  self.activationMessage = ko.pureComputed(function () {
    var validationErrors = ko.validation.group(mainModel.carrier);
    return validationErrors().length > 0 && !self.isReadOnly;
  });
  //The read portion of the following self.selectedIndex computed observable reevaluates and updates 'self.isActive' when dependencies change (validation errors),
  //and the write portion updates 'self.isActive' when a new value is selected from the active/inactive dropdown
  self.selectedIndex = ko.pureComputed({
    read: function () {
      var validationErrors = ko.validation.group(mainModel.carrier);
      // if this is a read only carrier do not update the active / inactive status on the initial load
      if (validationErrors().length == 0 || self.isReadOnly) {
        if (self.existingCarrier) {
          //no validation errors + existing carrier >> set isActive to existing carrier's status
          var CarrierActiveStatus = self
            .items()
            .map(function (e) {
              return e.value;
            })
            .indexOf(
              self.originalValue() != undefined ? self.originalValue() : false
            );
          self.isActive(
            self.originalValue() != undefined ? self.originalValue() : false
          );
          return CarrierActiveStatus;
        } else {
          //no validation errors + new carrier >> set isActive to true
          self.isActive(true);
          return self
            .items()
            .map(function (e) {
              return e.value;
            })
            .indexOf(true);
        }
      } else {
        //validation errors + new carrier OR existing carrier >> set isActive to false
        self.isActive(false);
        return self
          .items()
          .map(function (e) {
            return e.value;
          })
          .indexOf(false);
      }
    },
    write: function (value) {
      //active / inactive chosen from dropdown >> set isActive to selected value
      self.isActive(self.items()[value].value);
    },
    owner: self,
  });

  self.ActiveSelect = function () {
    var scrollTo = function (element) {
      $("html, body").animate({ scrollTop: element.offset().top - 50 }, "slow");
      element
        .css("background-color", "#FFFF9C")
        .animate({ backgroundColor: "" }, 5500);
    };
    scrollTo($("#Active"));
  };

  self.carrierManager = ko.observable(carrier.carrierManager || "N/A");
  self.code = ko.observable(carrier.code).extend({ required: true });
  self.name = ko.observable(carrier.name).extend({ required: true });
  self.address1 = ko.observable(carrier.address1).extend({ required: true });
  self.address2 = ko.observable(carrier.address2);
  self.cityStateZip = ko
    .observable(carrier.cityStateZip)
    .extend({ required: true });
  self.advance = ko.observable(carrier.advance || false);
  self.daysToPay = ko
    .observable(carrier.daysToPay || 21)
    .extend({ required: true });
  self.phone = ko.observable(carrier.phone).extend({ required: true });
  self.payeeType = carrier.payeeType;
  self.payFrequency = carrier.payFrequency || "";
  self.payMethod = ko.observable(carrier.payMethod || "P");
  self.type = ko.observable(carrier.type || "Carrier");
  self.typeList = ko.observableArray([
    "Carrier",
    "Permits",
    "Escorts",
    "Specialty",
  ]);
  self.isRemit = ko.observable(
    carrier.isRemit == null ? false : carrier.isRemit
  );
  self.remitName = ko.observable(carrier.remitName).extend({
    required: {
      onlyIf: function () {
        return !self.isRemit();
      },
    },
  });
  self.remitAddress1 = ko.observable(carrier.remitAddress1).extend({
    required: {
      onlyIf: function () {
        return !self.isRemit();
      },
    },
  });
  self.remitAddress2 = ko.observable(carrier.remitAddress2);
  self.remitCityStateZip = ko.observable(carrier.remitCityStateZip).extend({
    required: {
      onlyIf: function () {
        return !self.isRemit();
      },
    },
  });
  self.name.subscribe(function (newVal) {
    if (self.isRemit()) {
      self.remitName(newVal);
    }
  });
  self.address1.subscribe(function (newVal) {
    if (self.isRemit()) {
      self.remitAddress1(newVal);
    }
  });
  self.address2.subscribe(function (newVal) {
    if (self.isRemit()) {
      self.remitAddress2(newVal);
    }
  });
  self.cityStateZip.subscribe(function (newVal) {
    if (self.isRemit()) {
      self.remitCityStateZip(newVal);
    }
  });
  self.isRemitReadOnly = ko.computed(function () {
    return self.isRemit() || self.isReadOnly;
  });
  self.taxId = ko.observable(carrier.taxId).extend({ required: true });
  self.print1099 = ko.observable(carrier.print1099 || false);
  self.department = ko.observable(carrier.department || "G1");
  self.expenseAcct = ko
    .observable(carrier.expenseAcct || "54001001000004000")
    .extend({ required: true });
  self.wireCode = ko.observable(carrier.wireCode || "");
  self.showByDefault = ko.observable(carrier.showByDefault || false);
  self.isGwCarrierEntrant = ko.observable(
    userProfile.isGwCarrierEntrant || false
  );
  self.remark = ko.observable(carrier.Remark);
  self.mcNumber = ko.observable(carrier.mcNumber);
  self.dotNumber = ko.observable(carrier.dotNumber);
  self.performanceRating = ko.observable(carrier.performanceRating || "NEW");
  self.performanceRatingList = ko.observableArray([
    "NEW",
    "APPROVED",
    "DO NOT LOAD",
  ]);
  self.performanceRatingDate = ko
    .observable(carrier.performanceRatingDate)
    .extend({
      required: {
        onlyIf: function () {
          if (self.type() == "Carrier") {
            return true;
          }
          return false;
        },
      },
    });
  self.safetyRating = ko
    .observable(carrier.safetyRating || "N")
    .extend({ required: true });
  self.safetyRatingList = ko.observableArray([
    { name: "NONE", value: "N" },
    { name: "SATISFACTORY", value: "S" },
    { name: "CONDITIONAL", value: "C" },
    { name: "UNSATISFACTORY", value: "U" },
  ]);
  self.safetyRatingDate = ko.observable(carrier.safetyRatingDate).extend({
    required: {
      onlyIf: function () {
        if (self.type() == "Carrier" && self.safetyRating() != "N") {
          return true;
        }
        return false;
      },
    },
  });
  self.contract = ko.observable(carrier.contract || false);
  self.contractDate = ko.observable(carrier.contractDate);
  self.creditApproved = ko.observable(carrier.creditApproved || false);
  self.creditCheckDate = ko.observable(carrier.creditCheckDate).extend({
    required: {
      onlyIf: function () {
        if (self.type() == "Carrier") {
          return true;
        }
        return false;
      },
    },
  });
  self.certificateInsuranceFile = ko.observable(
    carrier.certificateInsuranceFile || false
  );
  self.autoInsuranceMonitoring = ko.observable(
    carrier.autoInsuranceMonitoring || false
  );
  self.liabilityExpirationDate = ko
    .observable(carrier.liabilityExpirationDate)
    .extend({
      required: {
        onlyIf: function () {
          if (self.type() == "Carrier") {
            return true;
          }
          return false;
        },
      },
      validation: {
        validator: function (val) {
          val = val instanceof dayjs ? val : dayjs(val);
          const yesterday = dayjs().add(-1, "day");
          return val.isAfter(yesterday);
        },
        message: "Date must be today or later.",
      },
    });
  self.liabilityAmount = ko
    .observable(carrier.liabilityAmount || 1000000)
    .extend({ required: true, min: 1000000 });
  self.liabilityInsuranceCompany = ko.observable(
    carrier.liabilityInsuranceCompany
  );
  self.liabilityInsurancePolicyNumber = ko.observable(
    carrier.liabilityInsurancePolicyNumber
  );
  self.cargoExclusions = ko.observable(carrier.cargoExclusions || false);
  self.cargoInsuranceRenewalDate = ko
    .observable(carrier.cargoInsuranceRenewalDate)
    .extend({
      required: {
        onlyIf: function () {
          if (self.type() == "Carrier") {
            return true;
          }
          return false;
        },
      },
      validation: {
        validator: function (val) {
          val = val instanceof dayjs ? val : dayjs(val);
          const yesterday = dayjs().add(-1, "day");
          return val.isAfter(yesterday);
        },
        message: "Date must be today or later.",
      },
    });
  self.cargoInsuranceAmount = ko
    .observable(carrier.cargoInsuranceAmount || 100000)
    .extend({ required: true, min: 100000 });
  self.cargoInsuranceCompany = ko.observable(carrier.cargoInsuranceCompany);
  self.cargoInsurancePolicyNumber = ko.observable(
    carrier.cargoInsurancePolicyNumber
  );
  self.workmansCompDate = ko.observable(carrier.workmansCompDate);
  self.californiaEmissions = ko.observable(
    carrier.californiaEmissions || false
  );
  self.interchangeAgreement = ko.observable(
    carrier.interchangeAgreement || false
  );
  self.trailerInterchange = ko.observable(carrier.trailerInterchange || false);
  self.trailerInterchangeInsuranceExpiration = ko.observable(
    carrier.trailerInterchangeInsuranceExpiration
  );
  self.trailerInterchangeInsuranceAmount = ko.observable(
    carrier.trailerInterchangeInsuranceAmount
  );
  self.trailerInterchangeInsuranceCompany = ko.observable(
    carrier.trailerInterchangeInsuranceCompany
  );
  self.trailerInterchangeInsurancePolicy = ko.observable(
    carrier.trailerInterchangeInsurancePolicy
  );
  self.currentAgencyId = ko.observable(carrier.currentAgencyId);

  self.intrastate = ko.observable(carrier.intrastate || false);
  self.remitChecked = function (item) {
    if (self.isRemit()) {
      self.remitName(self.name());
      self.remitAddress1(self.address1());
      self.remitAddress2(self.address2());
      self.remitCityStateZip(self.cityStateZip());
    }
  };
  self.remark = ko.observable(carrier.remark);
  self.contacts = ko
    .observableArray(
      ko.utils.arrayMap(carrier.contacts, function (item) {
        return new ContactModel(item, self, mainModel);
      })
    )
    .extend({
      liveEditor: { model: ContactModel, params: [{}, self, mainModel] },
    });
  self.contactTmplToUse = function (item) {
    return self.contacts.selectedItem() === item
      ? "contactEditTmpl"
      : "contactItemTmpl";
  };

  self.equipment = ko
    .observableArray(
      ko.utils.arrayMap(carrier.equipment, function (item) {
        return new EquipmentModel(item, self, mainModel);
      })
    )
    .extend({
      liveEditor: { model: EquipmentModel, params: [{}, self, mainModel] },
    });
  self.equipmentTmplToUse = function (item) {
    return self.equipment.selectedItem() === item
      ? "equipmentEditTmpl"
      : "equipmentItemTmpl";
  };

  self.carrierNotes = ko
    .observableArray(
      ko.utils.arrayMap(carrier.carrierNotes, function (item) {
        return new CarrierNoteModel(item, self, mainModel);
      })
    )
    .extend({
      liveEditor: { model: CarrierNoteModel, params: [{}, self, mainModel] },
    });
  self.carrierNotesTmplToUse = function (item) {
    return self.carrierNotes.selectedItem() === item
      ? "carrierNotesEditTmpl"
      : "carrierNotesItemTmpl";
  };

  self.profileRecords = ko
    .observableArray(
      ko.utils.arrayMap(carrier.profileRecords, function (item) {
        return new ProfileRecordModel(item, self, mainModel);
      })
    )
    .extend({
      liveEditor: { model: ProfileRecordModel, params: [{}, self, mainModel] },
    });
  self.profileRecordsTmplToUse = function (item) {
    return self.profileRecords.selectedItem() === item
      ? "profileRecordEditTmpl"
      : "profileRecordItemTmpl";
  };

  self.thirdPartyIntegration = ko
    .observableArray(
      ko.utils.arrayMap(carrier.tpeExternalCarrierData, function (item) {
        return new ThirdPartyIntegrationModel(item, self, mainModel);
      })
    )
    .extend({
      liveEditor: {
        model: ThirdPartyIntegrationModel,
        params: [{}, self, mainModel],
      },
    });
  self.thirdPartyIntegrationTmplToUse = function (item) {
    return self.thirdPartyIntegration.selectedItem() === item
      ? "thirdPartyIntegrationEditTmpl"
      : "thirdPartyIntegrationItemTmpl";
  };

  self.generageCode = function () {
    var data = {
      name: self.name() || "",
      cityState: self.cityStateZip() || "",
    };

    dataModel
      .ajaxRequest("Carrier/GenerateCode", "get", data)
      .done(function (data, textStatus, jqXHR) {
        self.code(data);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {});
  };

  self.isCallingLoading = ko.observable(false);
  self.isHistoryLoading = ko.observable(false);

  self.callInDaysBack = ko.observable(60);
  self.callInCutoffDate = ko.observable(new Date());
  self.callInRecords = ko.observableArray();
  self.getCallInRecords = function () {
    if (self.callInCutoffDate() == null) {
      return false;
    }
    var data = {
      carrierId: self.id(),
      cutOffDate: self.callInCutoffDate(),
      daysBack: self.callInDaysBack(),
    };

    self.isCallingLoading(true);
    dataModel
      .ajaxRequest("CallinRecords/CarrierCallIn", "post", data)
      .done(function (data, textStatus, jqXHR) {
        //  setTimeout(function () {
        self.callInRecords(data);
        self.isCallingLoading(false);
        //   }, 3000);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        self.isCallingLoading(false);
      });
  };

  self.laneHistoryDaysBack = ko.observable(60);
  self.laneHistoryCutoffDate = ko.observable(new Date());
  self.laneHistory = ko.observableArray();

  self.createOrderLink = function(orderId) {
    return "/Orders/" + orderId;
  };
  self.getLaneHistory = function () {
    if (self.laneHistoryCutoffDate() == null) {
      return false;
    }
    var data = {
      carrierId: self.id(),
      cutOffDate: self.laneHistoryCutoffDate(),
      daysBack: self.laneHistoryDaysBack(),
    };

    self.isHistoryLoading(true);
    dataModel
      .ajaxRequest("Carrier/laneHistory", "post", data)
      .done(function (data, textStatus, jqXHR) {
        var extendedData = data.map(function(item) {
          item.orderLink = self.createOrderLink(item.orderId);
          return item;
        });
        self.laneHistory(extendedData);
        self.isHistoryLoading(false);
        console.log(self.laneHistory());
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        self.isHistoryLoading(false);
      });
      console.log(self.laneHistory());
  };
 
  self.intrastateState = ko
    .observable(carrier.intrastateState)
    .extend({ maxLength: 2 });
  self.intrastateCode = ko
    .observable(carrier.intrastateCode)
    .extend({ maxLength: 15 });
  self.noDispatch = ko.observable(false);
};

export default { viewModel: CarrierEntryViewModel, template: template };

import template from "./tenders-page.html";
import {createGeDateRangeFilterWidget, GreatEdgeCustomWidgets} from "ge.custom-widgets";
import gridStateModel from "jqx.grid-state-component";
import {loadSavedSearches} from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
import gridUtils from "jqx.grid-utils";
import gridStateUtils from "jqx.grid-utils";
import dataModel from "data-model";
import {datetimeUTC, mapGridDate, noop, GenerateGuid} from "global-functions";
import userProfile from "user-profile";
import tendersHttpClient from "./tendersHttpClient";
import {Observable} from "knockout";
import {data} from "jquery";
import toastNotify from "geNotyf";

interface BidTenderModel {
    tenderNumber: string;
    loadId?: number;
    currentBid?: number;
    lastBidAmount?: number
}

class TendersPageViewModel {
    isLoading: Observable<boolean> = ko.observable(false);
    clearSelectedSavedSearchDDL: Observable<boolean> = ko.observable(false);
    refreshSavedSearchDDL: Observable<string> = ko.observable("");
    showCustomerSetupModal: Observable<boolean> = ko.observable(false);
    bidTenderData: Observable<BidTenderModel | null> = ko.observable(null);

    private $grid: JQuery<HTMLElement> = $('#jqxTendersGrid');
    private geDateWidget = new GreatEdgeCustomWidgets();
    //private jqxWidgetsGridHelper = this.geDateWidget.GEWidgetsGridHelper(this.$grid.attr('id'));
    private initGridState = null;
    private auctionTypeList = [{label: "", html: 'Any'}];
    private statusList = [
        {label: "", html: 'Any'},
        {label: 'Active', html: 'Active'},
        {label: 'Awarded', html: 'Awarded'},
        {label: 'Running', html: 'Running'},
        {label: 'Cancelled', html: 'Cancelled'},
        {label: 'Expired', html: 'Expired'},
    ];
    private equipmentTypeList = [{label: "", html: 'Any'}];
    private isGridInit = false;

    constructor() {
        this.loadTrailerTypeListAsync();
    }

    // Used in .html template
    handleCustomerSetupModalClose = () => this.showCustomerSetupModal(false);
    handleTenderBidModalClose = () => {
        this.bidTenderData(null);
        this.$grid.jqxGrid('updatebounddata', 'data');
    }

    private buildGrid = () => {
        const columns = this.gridColumns();

        const source = {
            url: "CustomerApi/Tenders",
            type: "GET",
            datafields: [
                {name: "customer", type: "string"},
                {name: "auctionNumber", type: "string"},
                {name: "mode", type: "string"},
                {name: "auctionType", type: "string"},
                {name: "status", type: "string"},
                {name: "currentBid", type: "number"}, // $$
                {name: "bidStatus", type: "boolean"},
                {name: "timeLeft", type: "string"},
                {name: "startDate", type: "date"},
                {name: "hazmat", type: "boolean"},
                {name: "tankerEndorsement", type: "boolean"},
                {name: "originName", type: "string"},
                {name: "origin", type: "string"},
                {name: "pickupDate", type: "date"},
                {name: "destinationName", type: "string"},
                {name: "destination", type: "string"},
                {name: "deliveryDate", type: "date"},
                {name: "stops", type: "int"},
                {name: "equipmentType", type: "string"},
                {name: "distance", type: "number"},
                {name: "weight", type: "number"},
                {name: "orderId", type: "string"},
                {name: "loadID", type: "string"},
                {name: "apiBid", type: "number"},
                {name: "allowBidding", type: "boolean"},
                {name: "autoBid", type: "boolean"},
                {name: "loadRate", type: "number"},
                {name: "carrierPayRate", type: "number"},
            ],
            datatype: "json",
            filter: () => {
                if (this.isGridInit) {
                    this.$grid.jqxGrid('updatebounddata', 'filter');
                }
            },
            sort: () => {
                if (this.isGridInit) {
                    this.$grid.jqxGrid('updatebounddata', 'sort');
                }
            },
            formatdata: (data = {}) => {

                const x = this.$grid.jqxGrid('getfilterinformation');
                const filters = gridStateUtils.formatGridFilters((x || []), data, true, {dateStartFilterPostFix: "Start", dateEndFilterPostFix: "End"});
                //const dateFilters = jqxWidgetsGridHelper.addGridFiltersToDataObj(data) || {};

                if(filters.mode && filters.mode?.toUpperCase() === "BOTH") {
                    filters.mode = "";
                }

                if(filters.bidStatus) {
                    filters.bidStatus = filters.bidStatus === 'Please Choose' ? null : filters.bidStatus === "Yes"
                }

                if(filters.hazmat) {
                    filters.hazmat = filters.hazmat === 'Please Choose' ? null : filters.hazmat === "Yes"
                }

                if(filters.tankerEndorsement) {
                    filters.tankerEndorsement = filters.tankerEndorsement === 'Please Choose' ? null : filters.tankerEndorsement === "Yes"
                }

                return {...filters, agency: userProfile.currentAgencyExId()};
            },
            beforeLoadComplete: (x, recordData) => {
                recordData = recordData || {tenders: []};

                return recordData.tenders.map(x => {
                    if (x.startDate) {
                        const d = datetimeUTC(mapGridDate(x.startDate));
                        x.startDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }
                    if (x.pickupDate) {
                        const d = datetimeUTC(mapGridDate(x.pickupDate));
                        x.pickupDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }
                    if (x.deliveryDate) {
                        const d = datetimeUTC(mapGridDate(x.deliveryDate));
                        x.deliveryDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }

                    if (x.bidStatus != null) {
                        x.bidStatus = x.bidStatus ? 'Yes' : 'No';
                    }

                    if (x.hazmat != null) {
                        x.hazmat = x.hazmat ? 'Yes' : 'No';
                    }

                    if (x.tankerEndorsement != null) {
                        x.tankerEndorsement = x.tankerEndorsement ? 'Yes' : 'No';
                    }

                    if (x.auctionType && this.auctionTypeList.some(q => q.html === x.auctionType) === false) {
                        this.auctionTypeList.push({html: x.auctionType, label: x.auctionType})
                    }

                    if(x.equipmentType) {
                        const isFilter = this.equipmentTypeList.filter(q => q.html?.toUpperCase() === x.equipmentType.toUpperCase())
                        if(!isFilter.length) {
                            this.equipmentTypeList.push({html: x.equipmentType, label: x.equipmentType});
                        }
                    }

                    return x;
                })

            }
        }

        const dataAdapter = dataModel.getDataAdapter(source);

        this.$grid.jqxGrid({
            theme: 'GWTMDark',
            width: "100%",
            source: dataAdapter,
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 20,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            enablebrowserselection: true,
            autoheight: false,
            height: window.innerHeight * 0.75,
            filterable: true,
            showfilterrow: true,
            showtoolbar: true,
            columns: columns,
            virtualmode: true,
            rendertoolbar: async (toolbar) => {
                const gridStateVM = new gridStateModel.viewModel();
                gridStateVM.pageName = "Tenders";
                var tmpl1 = gridStateModel.template;

                gridStateVM.actions.push("Export");
                gridStateVM.actions.push("Save Search");

                const roleNames = await userProfile.roles();

                if(userProfile.currentAgencyExId() !== 'EFLUTS' && roleNames.some((r:string) => ["ADMINISTRATORS","AGENCY MANAGER"].indexOf(r?.toUpperCase()) > -1)) {
                    gridStateVM.actions.push("Customer Setup");
                }

                const extraActionHandlers = {
                    export: (getDataFn, handlerFn) => {
                        return {
                            action: "Export",
                            handler: () => handlerFn(getDataFn())
                        }
                    },
                }
                
                gridStateVM.extraActionHandlers = [
                    extraActionHandlers.export(() => gridStateUtils.formatGridHeaders(this.$grid.attr('id'), false), this.handleExport),
                    { action: "Customer Setup", handler: () => this.showCustomerSetupModal(true) }
                ];

                gridStateVM.setDefaultSearchOverride = async () => {
                    const savedSearches = await loadSavedSearches(this.$grid.attr("id"));
                    const filters = {
                        isDefault: true
                    }

                    gridStateVM.loadSaveSearchModal(
                        this.$grid,
                        (val) => {
                            if(val && val.searchname) {
                                this.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                }

                gridStateVM.loadSaveSearchModalOverride = async () => {
                    const savedSearches = await loadSavedSearches(this.$grid.attr("id"));
                    const filters = {
                        isDefault: false
                    }

                    gridStateVM.loadSaveSearchModal(
                        this.$grid,
                        (val) => {
                            if(val && val.searchname) {
                                this.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                }

                gridStateVM.clearFilters = () => {
                    this.$grid.jqxGrid('clearfilters');
                    this.clearSelectedSavedSearchDDL(true);
                }

                const tendersGridAction = $("#tendersGridAction");
                tendersGridAction.append(tmpl1);
                const tendersGridTmpl = $("#tendersGridToolbar");
                toolbar.append(tendersGridTmpl);

                tendersGridTmpl.show();

                ko.applyBindingsToDescendants(gridStateVM, tendersGridAction[0]);
            },
            ready: () => {
                gridUtils.applyGridState(this.$grid.attr('id'), this.initGridState);
                this.isGridInit = true;
            },
            rendergridrows: (obj) => {
                return obj.data;
            },
        });
    }

    private loadTrailerTypeListAsync = async () => {
        const result = await tendersHttpClient.getTrailerTypesAsync(userProfile.currentAgencyExId()) as {totalRecords: number, trailerTypes: string[]};
        if(result) {
            this.equipmentTypeList = [...this.equipmentTypeList, ...result.trailerTypes.filter(x => x).map(x => ({label: x, html: x}))];
            this.$grid.on("bindingcomplete", () => {
                this.$grid.jqxGrid({columns: this.gridColumns()})
                this.$grid.off('bindingcomplete');
            })
        }
    }

    private gridColumns = () => {
        const columns: any[] = [
            { text: "Customer", datafield: "customer", minwidth: 200 },
            { text: "Auction Number", datafield: "auctionNumber", minwidth: 150 },
            { text: "Mode", datafield: "mode", minwidth: 100, filtertype: 'list', filteritems: [{html: "Both", label: "Both"},{html: "LTL", label: "LTL"}, {html: "TL", label: "TL"}] },
            { text: "Auction Type", datafield: "auctionType", minwidth: 100, filtertype: 'list', filteritems: this.auctionTypeList},
            { text: "Status", datafield: "status", minwidth: 150, filtertype: 'list', filteritems: this.statusList},
            { text: "Current Bid", datafield: "currentBid", minwidth: 150, cellsformat: 'c2' },
            { text: "Submitted Bid", datafield: "apiBid", minwidth: 150, cellsformat: 'c2' },
            { text: "Bid Status", datafield: "bidStatus", minwidth: 150, filtertype: 'list', filteritems: [{html: "Yes", label: "Yes"},{html: "No", label: "No"}], },
            { text: "Hazmat", datafield: "hazmat", minwidth: 100, filtertype: 'list', filteritems: [{html: "Yes", label: "Yes"},{html: "No", label: "No"}] },
            { text: "Tanker Endorsement", datafield: "tankerEndorsement", minwidth: 100, filtertype: 'list', filteritems: [{html: "Yes", label: "Yes"},{html: "No", label: "No"}] },
            { text: "Origin Name", datafield: "originName", minwidth: 200 },
            { text: "Origin", datafield: "origin", minwidth: 150 },
            { text: "Pickup Date", datafield: "pickupDate", minwidth: 200, createfilterwidget: createGeDateRangeFilterWidget(this.geDateWidget, this.$grid.attr('id'), this.initGridState?.grid) },
            { text: "Destination Name", datafield: "destinationName", minwidth: 200 },
            { text: "Destination", datafield: "destination", minwidth: 150 },
            { text: "Delivery Date", datafield: "deliveryDate", minwidth: 200,createfilterwidget: createGeDateRangeFilterWidget(this.geDateWidget, this.$grid.attr('id'), this.initGridState?.grid) },
            { text: "Equipment Type", datafield: "equipmentType", minwidth: 200, filtertype: 'list', filteritems: this.equipmentTypeList},
            { text: "Distance", datafield: "distance", minwidth: 100, filterable: false },
            { text: "Weight", datafield: "weight", minwidth: 100, filterable: false },
            { text: "Order Id", datafield: "orderId", minwidth: 150 }

        ];

        const agencyCode = userProfile.currentAgencyExId() ?? "";

        // Add Columns if not ArdentX 'Loreal' tenders flow.
        if(["EFLUTS"].indexOf(agencyCode) === -1) {
            columns.unshift({
                text: "",
                hidden: false,
                pinned: true,
                width: 50,
                menu: false,
                resizable: false,
                hideable: false,
                showheader: false,
                filterable: false,
                cellsrenderer: (
                    row,
                    columnfield,
                    value,
                    defaulthtml,
                    columnproperties
                ) => {
                    const data = (this.$grid.jqxGrid('getrowdata', row)) ?? {} as any;
                    const id = GenerateGuid(10);
                    const bidToolTip = data.allowBidding && !data.autoBid && data.status?.toUpperCase() === 'ACTIVE' ? '' : 'Bidding is not available for tender.';

                    const html = `<div class="tender-menu dropdown">
                      <button data-toggle="dropdown"  title="Options" style="cursor: pointer; opacity: 0.99; position: absolute; top: 0; left: 0; padding: 0; margin-top: 2px; margin-left: 2px; height: 28px; width: 45px" 
                                                    class="djqx-rc-all jqx-rc-all-GWTMDark jqx-button jqx-button-GWTMDark jqx-widget jqx-widget-GWTMDark">
                                                        <i class="glyphicon glyphicon-option-vertical"></i>
                                                    </button>
                      <ul class="dropdown-menu">
                        <li>
                            <a href="#" class="tenderGridPopupMenuItem-${id}" data-action="handleAcceptTender" 
                                style="color: #333!important; text-decoration: none; opacity: ${data.status?.toUpperCase() === 'ACTIVE' ? 1 : 0.7};" title="${data.status?.toUpperCase() === 'ACTIVE' ? '' : 'Tender is no longer active.'}">
                                Accept Tender
                             </a>
                        </li>
                        <li>
                            <a href="#" class="tenderGridPopupMenuItem-${id}" data-action="handleBidTender" 
                                style="color: #333!important;text-decoration: none; opacity: ${data.allowBidding && !data.autoBid && data.status?.toUpperCase() === 'ACTIVE' ? 1 : 0.7};" title="${bidToolTip}">
                                Place Bid
                            </a>
                        </li>
                      </ul>
                    </div>`

                    $(document).on('click', '.tenderGridPopupMenuItem-'+id, (event) => {
                        const $el = $(event.currentTarget);
                        const $parentDropdown = $el.closest('.dropdown');
                        const action = $el.attr('data-action');

                        if (action === "handleAcceptTender" && data.status?.toUpperCase() === 'ACTIVE') {
                            this.handleAcceptTender(data.auctionNumber);
                        } else if (action === "handleBidTender" && data.allowBidding && !data.autoBid && data.status?.toUpperCase() === 'ACTIVE') {
                            this.handleBidTender(data);
                        }

                        if($parentDropdown) {
                            $parentDropdown.removeClass('open');
                        }
                    })

                    return html;
                },
            })

            const spliceIndex = columns.findIndex(q => q.datafield === "bidStatus");

            columns.splice(spliceIndex + 1, 0, { text: "Tender Rate", datafield: "loadRate", minwidth: 150, cellsformat: 'c2' });
            columns.splice(spliceIndex + 2, 0, { text: "Carrier Pay Rate", datafield: "carrierPayRate", minwidth: 150, cellsformat: 'c2' });

            columns.push({
                text: "Load Id",
                datafield: "loadId",
                minwidth: 150,
                cellsrenderer: (
                    row,
                    columnfield,
                    value,
                    defaulthtml,
                    columnproperties
                ) => {
                    const $html = $(defaulthtml);

                    if(value) {
                        const link = `<a href="/LoadTractorPostings?loadId=${value}" target="_blank">${value}</a>`
                        $html.html(link)
                    }

                    return $html[0].outerHTML;
                }
            });
        }

        return columns;

    }

    handleOnSelected = (state: any) => {
        this.initGridState = state.grid || {};

        if(this.isGridInit == false){
            this.buildGrid()
        }
        else {
            gridStateUtils.applyGridState("jqxTendersGrid", state.grid);
        }
    }

    handleExport = (data: any) => {
        if(data) {
            dataModel.exportToCSV(data, `Tenders - Exported ${datetimeUTC(new Date()).format("MM-DD-YYYY-HHmmss")} `, true);
        }
    }

    handleAcceptTender = async (tenderId?: string) => {
        try {

            if(!tenderId) {
                return;
            }

            this.isLoading(true)
            await tendersHttpClient.sendTenderResponse(tenderId, "ACCEPT", userProfile.currentAgencyExId());
            toastNotify.success("Tender Accepted.");
        }
        catch (err) {
            toastNotify.error({message: err as string, duration: 5000});
        }

        this.isLoading(false)
        this.$grid.jqxGrid('updatebounddata', 'data');
    }

    handleBidTender = (data: any) => {
        if(data) {
            const tenderData = {
                tenderNumber: data.auctionNumber,
                loadId: data.loadId,
                lastBidAmount: data.apiBid,
                currentBid: data.currentBid
            }
            this.bidTenderData(tenderData);
        }
    }

    dispose = () => {
        $('[class^="tenderGridPopupMenuItem-"]').each(function() {
            $(this).off('click');
        });

        this.$grid.off('bindingcomplete');
    }

}

export default {viewModel: TendersPageViewModel, template }

